const config = {
  server: '/api/',
  // The meaning of versioning: 2.1.0
  // 1st - Major production version
  // 2nd - New functionality
  // 3rd - Fixed bug
  version: '1.0.45',
}

// if (location.hostname === 'localhost') {
  // config.server = 'http://localhost:3000/api/'
  // config.server = 'http://158.160.33.230/api/'
// }

export default config
