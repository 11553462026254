import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'

const apiSlug = 'files'

export const useFilesStore = defineStore(apiSlug, () => {
  const blobsMap = ref(new Map())

  async function setBlobsMap(id) {
    const response = await API.get(`${apiSlug}/${id}`, {}, {
      'Accept': 'application/octet-stream',
      'Accept-Language': undefined,
      'Content-Type': undefined,
    })
    let avatarUrl = null as any
    if (response?.data) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const urlCreator = window.URL || window.webkitURL
      avatarUrl = urlCreator.createObjectURL(blob)
      blobsMap.value.set(id, avatarUrl)
    }
  }

  const idsFiles = ref(new Set())
  async function setIdsFiles(id: any) {
    if (!idsFiles.value.has(id)) {
      idsFiles.value.add(id)
      await setBlobsMap(id)
    }
  }

  async function getFile(id: any) {
    if (!blobsMap.value.get(id)) {
      await setIdsFiles(id)
    }
    return blobsMap.value.get(id) || null
  }

  function clearBlobs() {
    for (const blob of blobsMap.value.values()) {
      URL.revokeObjectURL(blob)
    }
  }

  async function remove(id: any) {
    const response = await API.delete(`${apiSlug}/${id}`)
    return response
  }

  async function upload(payload: any) {
    const response = await API.post(`${apiSlug}/upload`, payload, { 'Content-Type': `multipart/form-data` })
    if (response?.data) {
      return response.data
    }
    return response
  }

  async function uploadImage(payload: any) {
    return await API.post(`${apiSlug}/upload`, payload, { 'Content-Type': `multipart/form-data`, 'Name': 'upload-image' })
  }

  return {
    getFile,
    blobsMap, idsFiles, clearBlobs,
    remove, upload, uploadImage
  }
})
