<template>
  <div
    class="m-tag"
    @click="selectTag(name)"
    :style="{ background: '#' + color }"
    :class="[
      {'m-tag-white' : color === 'white'},
      {'with-delete' : isDelete},
      {'m-tag-add cursor-pointer' : isAdd},
      {'m-tag-select cursor-pointer' : isSelect},
      {'m-tag-add_small' : isSmall},
    ]"
  >
    <span
      v-if="!isAdd"
      class="m-tag-value"
      :title="name"
    >
      {{ name }}
    </span>
    <div v-if="isAdd" @click="addTag()">
      + {{ isAdd }}
    </div>
    <span
      v-if="isDelete"
      class="icon-tag-delete cursor-pointer ml-4"
      @click="deleteTag()"
    />
  </div>
</template>

<script setup>
import {ref} from 'vue'

const emit = defineEmits(['onAdd', 'onDelete', 'onSelect'])
const props = defineProps({
  name: String,
  color: String,
  delete: {
    type: Boolean,
    default: false,
  },
  add: String,
  select: String,
  small: {
    type: Boolean,
    default: false,
  },
})

const isDelete = ref(props.delete)
const isAdd = ref(props.add)
const isSelect = ref(props.select)
const isSmall = ref(props.small)

const addTag = () => {
  emit('onAdd')
}
const deleteTag = () => {
  emit('onDelete')
}
const selectTag = (name) => {
  emit('onSelect', name)
}
</script>
