<template>
  <div>
    <Header />
    <Menu />
    <div
      v-if="commonStore.isAuth"
      class="p-container"
      :class="{ expanded: commonStore.expandMenu }"
    >
      <router-view v-slot="{ Component, route }">
        <component
          :is="Component"
          :key="route.path"
        />
      </router-view>
    </div>
    <div v-if="!commonStore.isAuth" class="p-container-login">
      <router-view v-slot="{ Component }">
        <component
          :is="Component"
        />
      </router-view>
    </div>

    <Toast
      class="info-messages"
      :breakpoints="{
        '780px': {
          'width': '100%',
          'right': '0',
        }
      }"
      position="bottom-center"
      group="br"
    />
  </div>
</template>

<script setup>
import Header from "@/components/header"
import Menu from "@/components/menu"
import { watch } from "vue"
import { useToast } from "primevue/usetoast"
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()
const toast = useToast()

watch(() => commonStore.successMessage, (message) => {
  if (message) {
    toast.add({
      severity: "success",
      summary: "",
      detail: message,
      group: "br",
      life: 5000,
    })
    commonStore.showSuccessMessage(null)
  }
})

watch(() => commonStore.errorMessage, (message) => {
  if (message) {
    toast.add({
      severity: "error",
      summary: "",
      detail: message,
      group: "br",
      closable: true,
    })
    commonStore.showErrorMessage(null)
  }
})
</script>
