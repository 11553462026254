import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useUsersStore } from './users'
import { useDepartmentsStore } from './departments'

const apiSlug = 'positions'

export const usePositionsStore = defineStore(apiSlug, () => {
  const usersStore = useUsersStore()
  const departmentsStore = useDepartmentsStore()

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data?.data) {
      const result: any[] = []
      for (const element of response.data.data) {
        result.push(await element)
      }
      setData(result)
    }
    return response
  }

  const dataOfPosition = ref([])
  function setDataOfPosition(payload: any) {
    dataOfPosition.value = payload
  }
  async function getById(id) {
    const response = await API.get(`${apiSlug}/${id}`)
    if (response?.data?.data) {
      await usersStore.load()
      setDataOfPosition(usersStore.data.filter((item: any) => item.positionId === id))
      return response.data.data
    }
    return response
  }

  async function preSave(payload) {
    delete payload.id
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(`${apiSlug}`, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    await load()
    departmentsStore.setIsLoaded(false)
  }

  return {
    data, load, isLoaded, setIsLoaded, getById, setData,
    save, create, remove, preSave, afterSuccessRequest,
    dataOfPosition, setDataOfPosition,
    apiSlug,
  }
})
