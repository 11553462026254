import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useTasksStore } from './tasks'
import { conditionsToString } from '@/common/utils'
import { useCommonStore } from '@/stores/common'

export const useSfTasksStore = defineStore('sfTasks', () => {
  const tasksStore = useTasksStore()
  const commonStore = useCommonStore()

  const statuses = {
    done: {
      status: 0,
      slug: 'done',
      name: commonStore.$t.taskStatuses.done,
      color: '#53C592',
      icon: 'pi pi-check-circle',
    },
    pending: {
      status: 1,
      slug: 'pending',
      name: commonStore.$t.taskStatuses.pending,
      color: '#CFD3E0',
      icon: 'pi pi-clock',
    },
    overdue: {
      status: 2,
      slug: 'overdue',
      name: commonStore.$t.taskStatuses.overdue,
      color: '#83DCEF',
      icon: 'pi pi-clock',
    },
    rejected: {
      status: 3,
      slug: 'rejected',
      name: commonStore.$t.taskStatuses.rejected,
      color: '#D24B64',
      icon: 'pi pi-times',
    },
  }

  const conditions = ref({})
  async function setConditions(payload: any) {
    conditions.value = payload
  }

  async function loadConditions() {
    const response = await API.get(`tasks/conditions`)
    if (response?.data) {
      response.data.conditions.statuses = response.data.conditions.statuses.map((item: any) => statuses[item.status])
      await setConditions(response.data.conditions)
    }
    return response
  }

  const selectedConditions = ref({})

  async function setSelectedConditions(payload: any) {
    Object.entries(payload).map((item: any) => {
      Object.entries(item[1]).filter((subItem: any) => subItem[1].length).length ? item : delete payload[item[0]]
    })
    if (Object.keys(payload).length === 0) {
      localStorage.removeItem(`smartFiltersTasks`)
    } else {
      localStorage[`smartFiltersTasks`] = JSON.stringify(payload)
    }
    let takeCounter = 15
    let skipCounter = 0
    if (localStorage.dt_state_tasks) {
      const localData = JSON.parse(localStorage.dt_state_tasks)
      takeCounter = localData.rows
      skipCounter = localData.first
    }
    await setFilterLinkType(localStorage.filterLinkTypeTasks === '' ? '' : 'smartFilter')
    await setFilterLinkText(payload)
    selectedConditions.value = payload
    await setFetchQuery({
      where: {
        smart: payload,
      },
      orderBy: {
        createdAt: 'desc',
      },
      take: takeCounter,
      skip: skipCounter,
    })
  }

  async function resetSelectedConditions() {
    selectedConditions.value = {}
    await setFilterLinkType('')
    await setFilterLinkText({})
    localStorage.removeItem(`smartFiltersTasks`)
    await setFetchQuery({
      where: {
        smart: {},
      },
      orderBy: {
        createdAt: 'desc',
      },
      take: 15,
      skip: 0,
    })
  }

  const whereQuery = ref({} as any)
  function setWhereQuery(payload) { whereQuery.value = { ...whereQuery.value, ...payload } }

  const sortQuery = ref({})
  function setSortQuery(payload) { sortQuery.value = payload }

  const takeQuery = ref(20)
  function setTakeQuery(payload) { takeQuery.value = payload }

  const skipQuery = ref(0)
  function setSkipQuery(payload) { skipQuery.value = payload }

  const fetchQuery = ref({
    where: undefined as any,
    orderBy: sortQuery.value,
    take: takeQuery.value,
    skip: skipQuery.value,
  })

  async function setFetchQuery(payload: any) {
    const resetPagination = () => {
      setTakeQuery(20)
      setSkipQuery(0)
    }
    if (payload.orderBy?.order && payload.orderBy?.field) {
      let query = {}
      const orderPayload = payload.orderBy
      const order = orderPayload.order === 1 ? 'asc' : 'desc'
      switch (orderPayload.field) {
        case 'responsible.name':
          query = { responsible: { name: order } }
          break
        case 'status.name':
          query = { status: order }
          break
        case 'item.check.location.name':
          query = { item: { check: { location: { name: order } } } }
          break
        default:
          query = { [orderPayload.field]: order }
          break
      }
      setSortQuery(query)
      resetPagination()
    }
    if (payload.where) {
      const wherePayload = payload.where
      const otherQuery: any = []
      let globalQuery: any = []
      if (wherePayload.global || wherePayload.global === '') {
        globalQuery = [
          { name: { contains: wherePayload.global, mode: 'insensitive' } },
          { tags: { hasSome: wherePayload.global } },
          { item: { check: { location: { name: { contains: wherePayload.global, mode: 'insensitive' } } } } },
          { item: { check: { location: { group: { name: { contains: wherePayload.global, mode: 'insensitive' } } } } } },
        ]
      }
      if (wherePayload.period) {
        const startDate = wherePayload.period?.start
          ? new Date(wherePayload.period.start).toJSON()
          : undefined
        const endDate = wherePayload.period?.end
          ? new Date(wherePayload.period.end).toJSON()
          : undefined
        otherQuery.push({
          finishDate: {
            gte: startDate,
            lte: endDate,
          },
        })
      }
      if (wherePayload.smart) {
        if (Object.keys(wherePayload.smart).length) {
          if (wherePayload.smart.locations) {
            otherQuery.push({ item: { check: { location: { name: { in: wherePayload.smart.locations } } } } })
          }
          if (wherePayload.smart.groups) {
            otherQuery.push({ item: { check: { location: { group: { name: { in: wherePayload.smart.groups } } } } } })
          }
          if (wherePayload.smart.audits) {
            otherQuery.push({ item: { check: { audit: { name: { in: wherePayload.smart.audits } } } } })
          }
          if (wherePayload.smart.requirements) {
            otherQuery.push({ item: { requirement: { name: { in: wherePayload.smart.requirements } } } })
          }
          if (wherePayload.smart.responsibles) {
            otherQuery.push({ responsible: { name: { in: wherePayload.smart.responsibles } } })
          }
          if (wherePayload.smart.statuses) {
            const slugsOfStatuses = wherePayload.smart.statuses.map(item => (item.status))
            otherQuery.push({ status: { in: slugsOfStatuses } })
          }
        } else {
          otherQuery.push({
            name: undefined,
            group: undefined,
            responsible: undefined,
            status: undefined,
          })
        }
      }
      if (globalQuery.length !== 0) { setWhereQuery({ OR: globalQuery }) }
      if (otherQuery.length !== 0) { setWhereQuery({ AND: otherQuery }) }
      resetPagination()
    }
    if (payload.take) {
      setTakeQuery(payload.take)
    }
    if (payload?.skip || payload.skip === 0) {
      setSkipQuery(payload.skip)
    }
    fetchQuery.value = {
      where: whereQuery.value,
      orderBy: sortQuery.value,
      take: takeQuery.value,
      skip: skipQuery.value,
    }
    tasksStore.setIsLoaded(false)
    await tasksStore.load()
  }

  async function resetFetchQuery(notLoad?: boolean) {
    fetchQuery.value = {
      where: undefined as any,
      orderBy: {
        createdAt: 'desc',
      },
      take: 15,
      skip: 0,
    }
    await resetSelectedConditions()
    if (!notLoad) {
      tasksStore.setIsLoaded(false)
      await tasksStore.load()
    }
  }

  const filterLinkText = ref('')
  async function setFilterLinkText(payload: any) {
    const copy = payload
    if (copy.statuses) {
      copy.statuses = copy.statuses.map(item => ({
        name: commonStore.$t.taskStatuses[item],
        status: statuses[item].slug,
      }))
    }
    filterLinkText.value = conditionsToString(copy)
  }

  const filterLinkType = ref('')
  async function setFilterLinkType(payload: string, reload?: boolean) {
    filterLinkType.value = payload
    localStorage[`filterLinkTypeTasks`] = payload
    let takeCounter = 15
    let skipCounter = 0
    if (localStorage.dt_state_tasks) {
      const localData = JSON.parse(localStorage.dt_state_tasks)
      takeCounter = localData.rows
      skipCounter = localData.first
    }
    if (reload) {
      switch (payload) {
        case '':
          fetchQuery.value = {
            where: undefined as any,
            orderBy: {
              createdAt: 'desc',
            },
            take: takeCounter,
            skip: skipCounter,
          }
          break
        case 'smartFilter':
          fetchQuery.value = {
            where: whereQuery.value,
            orderBy: {
              createdAt: 'desc',
            },
            take: takeCounter,
            skip: skipCounter,
          }
          break
        case 'object':
          break
        default:
          break
      }
      tasksStore.setIsLoaded(false)
      await tasksStore.load()
    }
  }

  return {
    statuses,
    fetchQuery, setFetchQuery, resetFetchQuery,
    conditions, loadConditions,
    selectedConditions, setSelectedConditions, resetSelectedConditions,
    filterLinkText, setFilterLinkText,
    filterLinkType, setFilterLinkType,
  }
})
