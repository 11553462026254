import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'reports',
    component: () => import('../views/reports.vue'),
  },
  {
    path: '/objects',
    name: 'objects',
    component: () => import('../views/objects.vue'),
  },
  {
    path: '/checklists',
    name: 'checklists',
    component: () => import('../views/checklists.vue'),
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import('../views/audit.vue'),
  },
  {
    path: '/audit-create',
    name: 'audit-create',
    component: () => import('../views/auditCreate.vue'),
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('../views/tasks.vue'),
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import('../views/manage.vue'),
    children: [
      {
        path: 'tenants',
        name: 'tenants',
        component: () => import('../views/manage/tenants.vue'),
      },
      {
        path: 'objects',
        name: 'objects&groups',
        component: () => import('../views/manage/objects&groups.vue'),
        children: [
          {
            path: ':id',
            name: 'objects-detail',
            component: () => import('../views/manage/objects.vue'),
          },
        ],
      },
      {
        path: '/manage/categories',
        name: 'categories',
        component: () => import('../views/manage/categories.vue'),
        children: [
          {
            path: ':id',
            name: 'category',
            component: () => import('../views/manage/category.vue'),
          },
        ],
      },
      {
        path: '/manage/requirements',
        name: 'requirements',
        component: () => import('../views/manage/requirements.vue'),
      },
      {
        path: 'departments',
        name: 'departments',
        component: () => import('../views/manage/departments.vue'),
        children: [
          {
            path: ':sId',
            name: 'positions',
            component: () => import('../views/manage/positions.vue'),
            children: [
              {
                path: 'users/:posId',
                name: 'position-users',
                component: () => import('../views/manage/position-users.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/manage/users',
        name: 'users',
        component: () => import('../views/manage/users.vue'),
      },
      {
        path: '/manage/tags',
        name: 'tags',
        component: () => import('../views/manage/tags.vue'),
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile.vue'),
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company.vue'),
  },
  {
    path: '/profile-edit',
    name: 'profile-edit',
    component: () => import('../views/profile-edit.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/init',
    name: 'init',
    component: () => import('../views/init.vue'),
  },
  {
    path: '/registration/:id',
    name: 'Registration',
    component: () => import('../views/registration.vue'),
  },
  {
    path: '/change-email/:id',
    name: 'EmailChange',
    component: () => import('../views/change-email.vue'),
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/reset.vue'),
    children: [
      {
        path: ':id',
        name: 'Reset',
        component: () => import('../views/reset.vue'),
      },
    ],
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'reports',
    component: () => import('../views/reports.vue'),
  },
]
