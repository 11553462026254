<template>
  <div v-if="searchStore.searchQuery !== ''">
    <div v-for="(entity, type) in found" :key="entity.type">
      <section class="search-result" v-if="type !== 'Tags' && type !== 'Метки'">
        <h2 class="mo-block-header">
          {{ type }}
        </h2>
        <ul v-for="found in entity" :key="found.id" class="search-result__list ul-custom">
          <li class="search-result__item">
            <div class="task-result">
              <a @click.once="openPage(found)" class="p-link task-result__link">
                <div v-if="highlighted" v-html="highlight(found.name)" />
                <div v-if="!highlighted">
                  {{ found.name }}
                </div>
              </a>
              <div class="task-result__date">
                {{ dateFormat(found.createdAt) }} {{ timeFormat(found.createdAt) }}
              </div>
            </div>
          </li>
        </ul>
      </section>
      <section v-else class="search-result">
        <h2 class="mo-block-header">
          {{ commonStore.$t.pages.tags.title }}
        </h2>
        <a
          v-for="(tag, type) in entity"
          :key="type"
          @click.once="openPage(tag)"
        >
          <MTag
            :key="tag.name"
            :name="tag.name"
            :color="tag.color"
            class="tag-animated"
          />
        </a>
      </section>
    </div>

    <div
      v-if="Object.keys(found).length === 0"
      class="search-result-footer mt-4 font-14"
    >
      {{ commonStore.$t.primevue.emptyFilterMessage }}
    </div>
  </div>
</template>

<script setup>
import { onMounted, toRefs } from "vue"
import { useRoute } from "vue-router"
import { dateFormat, timeFormat } from "@/common/utils"
import { useSearchStore } from '@/stores/search'
import router from '@/router'
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()
const searchStore = useSearchStore()

const route = useRoute()
const props = defineProps({
  found: {
    type: Object,
    default: {}
  },
  highlighted: Boolean,
})

const { found } = toRefs(props)
const { highlighted } = toRefs(props)

const openPage = async (payload) => {
  router.push(await searchStore.searchElementLink(payload))
}

const highlight = str => {
  return str.replace(new RegExp(searchStore.searchQuery, "gi"), match => {
    return '<span class="focused-element">' + match + '</span>'
  })
}
</script>
