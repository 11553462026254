<template>
  <div>
    <div class="flex align-items-center mb-4">
      <InputText
        v-model="searchQuery"
        type="text"
        id="search-tags-input"
        class="search-input"
        :placeholder="commonStore.$t.actions.search"
        name="search-tag"
      />
      <a
        class="p-link ml-3"
        @click="addTag"
        id="addTagButton"
      >
        {{commonStore.$t.pages.tags.addTag}}
      </a>
    </div>
    <div v-if="searchResult.length > 0" class="mt-3 tag-wrapper">
      <transition-group name="tag-add">
        <MTag
          v-for="tag in searchResult"
          :key="tag"
          :name="tag.name"
          :color="tag.color"
          select="true"
          class="tag-animated"
          @on-select="select($event)"
        />
      </transition-group>
    </div>
    <Toast
      class="info-messages"
      id="tags-toast-modal"
      position="bottom-center"
      :breakpoints="{ '780px': { width: '100%', right: '0' } }"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { computed } from 'vue'

import { useTagsStore } from '@/stores/tags'
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()
const tagsStore = useTagsStore()

const emit = defineEmits(['onSelect'])
const props = defineProps({
  entity: Object,
})

const searchQuery = ref()
const tags = ref([])
const entity = ref(props.entity)

onMounted(async () => {
  await tagsStore.load()
  tags.value = await tagsStore.data
  filterTags()
})

const filterTags = () => {
  if (entity.value && entity.value.tagObjects)
    entity.value.tagObjects.forEach(t => {
      tags.value = tags.value.filter(tag => tag.name !== t.name)
    })
}

const searchResult = computed(() => {
  if (searchQuery.value) {
    return tags.value.filter((item)=>{
      return searchQuery.value.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
    })
  } else {
    return tags.value
  }
})

const select = (name) => {
  emit('onSelect', name)
  tags.value = tags.value.filter(tag => tag.name !== name)
}

const addTag = () => {
  if (!searchQuery.value || !searchQuery.value.replace(/\s/g, '').length) {
    commonStore.showErrorMessage(commonStore.$t.validation.name.emptyTagName)
    return
  }

  if (!entity.value?.tagObjects?.find(t => t.name === searchQuery.value)) {
    emit('onSelect', searchQuery.value.trim())
    searchQuery.value = ''
  } else {
    commonStore.showErrorMessage(commonStore.$t.pages.tags.tagAlreadyAdded)
  }
}
</script>
