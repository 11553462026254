import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import { useDepartmentsStore } from './departments'
import { usePositionsStore } from './positions'
import { useLocationsStore } from './locations'
import { useTasksStore } from './tasks'
import { useAuditsStore } from './audits'
import { useCommonStore } from '@/stores/common'

const apiSlug = 'users'

export const useUsersStore = defineStore(apiSlug, () => {
  const authStore = useAuthStore()
  const positionsStore = usePositionsStore()
  const departmentsStore = useDepartmentsStore()
  const locationsStore = useLocationsStore()
  const tasksStore = useTasksStore()
  const auditsStore = useAuditsStore()
  const commonStore = useCommonStore()

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
    setTableLoading(false)
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data) {
      const result: any[] = []
      for (const element of response.data) {
        result.push(element)
      }
      setData(result)
    }
    return response
  }

  const roles = ref([
    { slug: 'ADMIN', name: commonStore.$t.roles.admin },
    { slug: 'MANAGER', name: commonStore.$t.roles.manager },
    { slug: 'AUDITOR', name: commonStore.$t.roles.auditor },
  ])

  function getRolesString(payload: any) {
    const rolesArray = [] as any
    for (const element of payload) {
      const role = roles.value.find((item: any) => item.slug === element)?.name
      if (role) { rolesArray.push(role) }
    }
    return rolesArray.join(', ')
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }

  const isShowSidebar = ref(false)
  function setShowSidebar(payload: boolean) {
    isShowSidebar.value = payload
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  const isTableLoading = ref(true)
  function setTableLoading(payload: boolean) {
    isTableLoading.value = payload
  }

  async function getAllResponsibles() {
    await locationsStore.load()
    const tasks = await tasksStore.getAll()
    const audits = await auditsStore.getAll()
    return {
      locations: locationsStore.data.map((item: any) => ({ id: item.id, name: item.name, groupId: item.groupId })),
      tasks: tasks.map((item: any) => ({ id: item.id, name: item.name })),
      audits: audits.map((item: any) => ({ id: item.id, name: item.name })),
    }
  }

  async function getResponsibles(userId: string) {
    const response = await API.get(`responsibles/${userId}`)
    if (response?.data) {
      return {
        locations: response.data?.locations
          ? response.data?.locations.map((item: any) => ({ id: item.id, name: item.name, groupId: item.groupId }))
          : [],
        tasks: response.data?.tasks
          ? response.data?.tasks.map((item: any) => ({ id: item.id, name: item.name }))
          : [],
        audits: response.data?.audits
          ? response.data?.audits.map((item: any) => ({ id: item.id, name: item.name }))
          : [],
      }
    }
    return response
  }

  function getOnlyIDs(payload) {
    payload.tasks = payload.tasks.map(item => item.id)
    payload.audits = payload.audits.map(item => item.id)
    payload.locations = payload.locations.map(item => item.id)
    return payload
  }

  async function getRemovedResponsibles(oldValues, newValues) {
    const preparedNewValues = getOnlyIDs(newValues)
    const tasks = oldValues.tasks.filter((item: any) => !preparedNewValues.tasks.includes(item))
    const locations = oldValues.locations.filter((item: any) => !preparedNewValues.locations.includes(item))
    const audits = oldValues.audits.filter((item: any) => !preparedNewValues.audits.includes(item))
    return {
      data: getOnlyIDs({
        tasks,
        locations,
        audits,
      }),
      total: tasks.length + locations.length + audits.length,
    }
  }

  async function setResponsibles(id: string, payload: {}) {
    const oldValues = await getResponsibles(id)
    const removedResponsibilities = await getRemovedResponsibles(oldValues, payload)
    if (removedResponsibilities.total !== 0) {
      const resultRemove = await API.delete(`responsibles`, removedResponsibilities.data)
    }
    const result = await API.patch(`responsibles/${id}`, payload)
    return result
  }

  async function preSave(payload: any) {
    delete payload.id
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function restoreVisibility(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, { hidden: false })
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    await load()
    authStore.setIsLoaded(false)
    await authStore.load()
    positionsStore.setIsLoaded(false)
    departmentsStore.setIsLoaded(false)
  }

  // TODO: import/export
  async function importFile(payload: any[]) {
    const response = await API.post(`${apiSlug}/import`, payload)
    if (response?.status < 300) {
      await afterSuccessRequest()
    }
    return response
  }

  async function exportFile() {
    const response = await API.get(`${apiSlug}/export/`)
    if (response?.status < 300) {
      await afterSuccessRequest()
    }
    return response
  }

  return {
    data, load, roles, getRolesString, isLoaded, setIsLoaded,
    isShowSidebar, setShowSidebar, preSave, afterSuccessRequest,
    isShowModal, setShowModal, setData,
    isTableLoading, setTableLoading, getOnlyIDs,
    save, create, remove, restoreVisibility,
    getResponsibles, setResponsibles, getAllResponsibles,
    apiSlug, importFile, exportFile,
  }
})
