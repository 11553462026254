<template>
  <div class="search-modal-wrapper">
    <form
      class="search-form mr-6"
      @click="openSearchMenu"
      @submit.stop.prevent="openSearchPage"
    >
      <div style="position: relative">
        <input
            class="mo-custom-input--search mo-custom-input"
            style="border: 0"
            aria-controls="overlay_panel3"
            :placeholder="commonStore.$t.actions.search"
            @input="processChange"
            v-model="searchQuery"
        />
        <i
            v-if="searchQuery"
            class="p-dropdown-clear-icon pi pi-times pi-objects-search"
            @click="clearSearch"
        />
      </div>
    </form>
    <OverlayPanel
      v-if="route.name !== 'search'"
      ref="searchModal"
      appendTo="body"
      id="overlay_panel3"
      class="header-overlay"
    >
      <SearchDialog />
    </OverlayPanel>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { debounce } from '@/common/utils'
import SearchDialog from '@/components/searchDialog'
import router from '@/router'
import { useSearchStore } from '@/stores/search'
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()
const searchStore = useSearchStore()

const searchModal = ref()
const searchMenu = ref(false)
const searchQuery = ref('')

const route = useRoute()

const processChange = debounce((event) => onInput(event))
const onInput = async (event) => {
  searchStore.setSearchQuery(event.target.value)
}

watch(() => searchStore.searchQuery, async () => {
  if (searchStore.searchQuery === '') {
    searchQuery.value = ''
  }
})

const openSearchMenu = event => {
  searchQuery.value = ''
  if (route.name !== 'search') {
    searchModal.value.show(event)
  }
}

const clearSearch = event => {
  searchQuery.value = ''
  searchStore.setSearchQuery('')
  if (route.name !== 'search') {
    searchModal.value.hide(event)
  }
}

const openSearchPage = () => {
  router.push({ name: 'search' })
}
</script>
