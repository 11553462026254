import locales from '@/common/locales'
import API from '@/api'
import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import globalProperties from '@/main'

export const useCommonStore = defineStore('common', () => {
  const lang = ref(localStorage.lang || 'en')
  function setLang(payload: any) {
    localStorage[`lang`] = payload.code
    globalProperties.$primevue.config.locale = locales[localStorage[`lang`]].primevue
    lang.value = payload.code
  }

  const $t = computed(() => locales[lang.value])

  const expandMenu = ref(false)
  function setExpandMenu(value: boolean) {
    expandMenu.value = value
  }

  const isAuth = ref(false)
  function setIsAuth(value: boolean) {
    isAuth.value = value
  }

  async function getFilterConditions(entity: any) {
    try {
      const result = await API.get(`condition/${entity}`)
      if (result.ok) {
        return result.conditions
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  async function hideSidebar(event) {
    const target = event.target
    const row = document.querySelector('.p-datatable-tbody')
    const header = document.querySelector('.p-rowgroup-header')
    const sidebar = document.querySelector('.p-sidebar')
    const op = document.querySelector('.p-overlaypanel')
    const inRow = target === row || row?.contains(target)
    const inHeader = target === header || header?.contains(target)
    const inSidebar = target === sidebar || sidebar?.contains(target)
    const inOp = target === op || op?.contains(target)
    return !((!inRow && !inSidebar && !inOp) || inHeader)
  }

  const auditsSmartFilterStatuses = computed(() => [
    { slug: 'pending', name: $t.value.auditStatus.pending },
    { slug: 'progress', name: $t.value.auditStatus.progress },
    { slug: 'correct', name: $t.value.auditStatus.correct },
    { slug: 'noncomformities', name: $t.value.auditStatus.noncomformities },
  ])

  const tasksSmartFilterStatuses = computed(() => [
    { slug: 'done', name: $t.value.taskStatuses.done, color: '#53C592', icon: 'pi pi-check-circle' },
    { slug: 'pending', name: $t.value.taskStatuses.pending, color: '#CFD3E0', icon: 'pi pi-clock'  },
    { slug: 'overdue', name: $t.value.taskStatuses.overdue, color: '#83DCEF', icon: 'pi pi-clock'  },
    { slug: 'rejected', name: $t.value.taskStatuses.rejected, color: '#D24B64', icon: 'pi pi-times'  },
  ])

  const tasksEditStatuses = computed(() => [
    { slug: 'done', name: $t.value.taskStatuses.done },
    { slug: 'pending', name: $t.value.taskStatuses.pending },
  ])

  const roles = computed(() => [
    { slug: 'ADMIN', name: $t.value.roles.admin },
    { slug: 'MANAGER', name: $t.value.roles.manager },
    { slug: 'AUDITOR', name: $t.value.roles.auditor },
  ])

  const planning = computed(() => [
    { slug: 'single', name: $t.value.planning.single },
    { slug: 'day', name: $t.value.planning.day },
    { slug: 'week', name: $t.value.planning.week },
    { slug: 'month', name: $t.value.planning.month },
    { slug: 'year', name: $t.value.planning.year },
  ])

  const errorMessage = ref('')
  function showErrorMessage(message: string) {
    errorMessage.value = message
  }

  const successMessage = ref('')
  function showSuccessMessage(message: string) {
    successMessage.value = message
  }

  return {
    $t, setLang,
    errorMessage, showErrorMessage,
    successMessage, showSuccessMessage,
    isAuth, setIsAuth,
    expandMenu, setExpandMenu,
    getFilterConditions,
    hideSidebar,
    auditsSmartFilterStatuses, roles, planning,
    tasksEditStatuses, tasksSmartFilterStatuses,
  }
})
