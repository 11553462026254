import config from '@/config'
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'
import globalProperties from '@/main'
import locales from '@/common/locales'

import { useAuthStore } from '@/stores/auth'
import { useCommonStore } from '@/stores/common'
import { useSearchStore } from '@/stores/search'
import { useLogoutStore } from '@/stores/logout'
import { useFilesStore } from '@/stores/files'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  const commonStore = useCommonStore()
  const searchStore = useSearchStore()
  const logoutStore = useLogoutStore()
  const filesStore = useFilesStore()

  const authUrls = (
    to.name === 'Login' ||
    to.name === 'Registration' ||
    to.name === 'Reset' ||
    to.name === 'init'
  )

  if (!localStorage.lang) {
    localStorage.lang = 'en'
  }

  if (
    localStorage[`version`] !== undefined &&
    localStorage[`version`] !== '' &&
    localStorage[`version`] !== config.version
  ) {
    await logoutStore.logout()
  } else {
    localStorage.version = config.version
  }

  // Reset search query
  if (to.name !== 'search') {
    searchStore.setSearchQuery('')
  }

  if (localStorage.token && localStorage.user && !authStore.token) {
    const parsedUser = JSON.parse(localStorage.user)
    authStore.setToken(localStorage.token)
    authStore.setRefreshToken(localStorage.refreshToken)
    authStore.setData(parsedUser)
    authStore.setIsAdmin(parsedUser.roles.includes('ADMIN') || parsedUser.roles.includes('SUPER_ADMIN'))
    authStore.setTenant(JSON.parse(localStorage.tenant))
    authStore.setIsSuperAdmin(parsedUser.roles.includes('SUPER_ADMIN'))
    commonStore.setIsAuth(true)
  }

  if (localStorage.license && Object.keys(authStore.license).length === 0) {
    authStore.setLicense(JSON.parse(localStorage.license))
  }

  if (localStorage.featureFlags && Object.keys(authStore.featureFlags).length === 0) {
    authStore.setFeatureFlags(JSON.parse(localStorage.featureFlags))
  }

  if (to.name === 'init') {
    const checkExistence = await authStore.checkExistenceSuperUser()
    if (checkExistence?.status && checkExistence.status === 200) {
      authStore.setIsCloud(checkExistence.data.featureFlags.isCloud)
    } else {
      return { name: 'Login' }
    }
  }

  if (!from.name) {
    filesStore.clearBlobs()
  }

  if (!authStore.isSuperAdmin && to.name === 'tenants') {
    return { name: 'reports' }
  }

  if (authUrls && authStore.token) {
    return { name: 'reports' }
  }

  if (authStore.token) {
    globalProperties.$primevue.config.locale = locales[localStorage.lang].primevue
    const protectedRoutes = '/manage'
    if (to.path.includes(protectedRoutes) && !authStore.isAdmin) {
      return { name: 'reports' }
    }
  } else {
    if (!authUrls && to.name !== 'EmailChange') {
      return { name: 'Login' }
    }
  }
})

router.onError(error => {
  console.error(error)
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload()
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
