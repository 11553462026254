import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useCommonStore } from './common'
import { useLogoutStore } from './logout'
import { useLocationsStore } from './locations'
import { useFilesStore } from './files'
import config from '@/config'

const apiSlug = 'auth'

export const useAuthStore = defineStore(apiSlug, () => {
  const commonStore = useCommonStore()
  const logoutStore = useLogoutStore()
  const locationsStore = useLocationsStore()
  const filesStore = useFilesStore()

  const data = ref({} as any)
  async function setData(payload: any) {
    localStorage[`user`] = JSON.stringify(payload)
    if (payload.photo) {
      payload.profilePhoto = await filesStore.getFile(payload.photo)
    }
    data.value = payload
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(`profile`)
    if (!response?.data?.message) {
      setData(response.data)
      setIsAdmin(response.data.roles.includes('ADMIN') || response.data.roles.includes('SUPER_ADMIN'))
      setIsSuperAdmin(response.data.roles.includes('SUPER_ADMIN'))
      commonStore.setIsAuth(true)
      locationsStore.setIsLoaded(false)
      setIsLoaded(true)
    } else {
      await logoutStore.logout()
    }
    return response
  }

  function setLoginData(payload) {
    setToken(payload.token)
    setTenant({...payload.tenant, id: payload.user.tenantId })
    setRefreshToken(payload.refresh_token)
    setLicense(payload.user.license)
    setFeatureFlags(payload.featureFlags)
    localStorage[`version`] = config.version
  }

  async function login(payload: any) {
    const response = await API.post(`${apiSlug}/login`, payload)
    if (response?.data?.token) {
      setLoginData(response.data)
      const result = await load()
      return result.data
    }
    return response
  }

  async function reLogin(payload: string) {
    if (!payload) {
      await logoutStore.logout()
    }
    const response = await API.post(`${apiSlug}/refresh/${payload}`)
    if (!response.data?.message) {
      setLoginData(response.data)
      setData(response.data.user)
      setIsAdmin(response.data.user.roles.includes('ADMIN') || response.data.user.roles.includes('SUPER_ADMIN'))
      setIsSuperAdmin(response.data.user.roles.includes('SUPER_ADMIN'))
      commonStore.setIsAuth(true)
      locationsStore.setIsLoaded(false)
      return response.data
    } else {
      await logoutStore.logout()
    }
  }

  async function updateProfile(payload: any) {
    const preparedPayload = {
      name: payload.name || undefined,
      phone: payload.phone || payload.phone === '' ? payload.phone : undefined,
      photo: payload.photo || payload.photo === '' ? payload.photo : undefined,
      address: payload.address || undefined,
      color: payload.color || undefined,
    }
    const response = await API.patch(`profile`, preparedPayload)
    setIsLoaded(false)
    await load()
    return response
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    const response = await API.post(`${apiSlug}/changePassword`, {
      oldPassword,
      newPassword,
    })
    return response
  }

  const license = ref({})
  function setLicense(payload: any) {
    license.value = payload
    localStorage[`license`] = JSON.stringify(payload)
  }

  const token = ref('')
  function setToken(payload: string) {
    token.value = payload
    localStorage[`token`] = payload
  }

  const tenant = ref({})
  function setTenant(payload: string) {
    tenant.value = payload
    localStorage[`tenant`] = JSON.stringify(payload)
  }

  const featureFlags = ref({})
  function setFeatureFlags(payload: string) {
    featureFlags.value = payload
    localStorage[`featureFlags`] = JSON.stringify(payload)
  }

  const refreshToken = ref('')
  function setRefreshToken(payload: string) {
    refreshToken.value = payload
    localStorage[`refreshToken`] = payload
  }

  const isAdmin = ref(false)
  function setIsAdmin(value: boolean) {
    isAdmin.value = value
  }

  const isSuperAdmin = ref(false)
  function setIsSuperAdmin(value: boolean) {
    isSuperAdmin.value = value
  }

  async function register(code: string, password: string) {
    const response = await API.post(`auth/confirm/register/${code}`, {}, { password })
    return response
  }

  async function resetPassword(email: string) {
    email = encodeURI(email)
    const response = await API.post(`${apiSlug}/resetPassword/${email}`)
    return response.data
  }

  async function confirmResetPassword(payload: any) {
    const response = await API.post(`${apiSlug}/confirm/reset/${payload.code}`, {}, {
      password: payload.password,
      accept: 'application/json',
    })
    return response
  }

  async function checkExistenceSuperUser() {
    const response = await API.get(`init`)
    return response
  }

  async function init(payload: any) {
    const initSuperUser = await API.post('init', payload)
    if (initSuperUser?.data?.user) {
      return initSuperUser.data.user
    } else {
      return false
    }
  }

  const isCloud = ref(null)
  function setIsCloud(payload: any) {
    isCloud.value = payload
  }

  async function getCompany() {
    const response = await API.get(`profile/tenant`)
    return response?.status === 200 ? response.data : {}
  }

  async function updateCompany(payload: any) {
    const preparedData = {
      name: payload.name || undefined,
      license: payload.license || undefined,
      analyticsLink: payload.analyticsLink || undefined,
    }
    const response = await API.patch(`profile/tenant`, preparedData)
    if (response.status === 200) {
      setLicense(response.data)
      return response.data
    }
    return response
  }

  async function changeEmail(email: string) {
    const response = await API.post(`${apiSlug}/changeEmail/${email}`)
    return response
  }

  async function confirmEmail(code: string) {
    const response = await API.post(`${apiSlug}/confirm/changeEmail/${code}`)
    return response
  }

  return {
    data, tenant, setData, setTenant,
    load, isLoaded, setIsLoaded, license, setLicense,
    token, setToken, refreshToken, setRefreshToken,
    isAdmin, setIsAdmin, isSuperAdmin, setIsSuperAdmin,
    login, reLogin, register, updateProfile, init,
    resetPassword, changePassword, confirmResetPassword, checkExistenceSuperUser,
    isCloud, setIsCloud, featureFlags, setFeatureFlags,
    getCompany, updateCompany, changeEmail, confirmEmail,
  }
})
