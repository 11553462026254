import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useAuditsStore } from './audits'
import { conditionsToString } from '@/common/utils'
import { useCommonStore } from '@/stores/common'

export const useSfAuditsStore = defineStore('sfAudits', () => {
  const auditsStore = useAuditsStore()
  const commonStore = useCommonStore()

  const statuses = {
    pending: {
      status: 0,
      slug: 'pending',
      name: commonStore.$t.auditStatus.pending,
    },
    progress: {
      status: 1,
      slug: 'progress',
      name: commonStore.$t.auditStatus.progress,
    },
    correct: {
      status: 2,
      slug: 'correct',
      name: commonStore.$t.auditStatus.correct,
    },
    noncomformities: {
      status: 3,
      slug: 'noncomformities',
      name: commonStore.$t.auditStatus.noncomformities,
    },
  }

  const globalFilter = ref('')
  function setGlobalFilter(payload) { globalFilter.value = payload }

  const globalPeriod = ref([])
  function setGlobalPeriod(payload) { globalPeriod.value = payload }

  const conditions = ref({})
  async function setConditions(payload: any) {
    conditions.value = payload
  }
  async function loadConditions() {
    const response = await API.get(`audits/conditions`)
    if (response?.data) {
      response.data.conditions.statuses = response.data.conditions.statuses.map((item: any) => statuses[item.status])
      await setConditions(response.data.conditions)
    }
    return response
  }

  const selectedConditions = ref({})
  async function setSelectedConditions(payload: any) {
    selectedConditions.value = payload
    Object.entries(payload).map((item: any) => {
      Object.entries(item[1]).filter((subItem: any) => subItem[1].length).length ? item : delete payload[item[0]]
    })
    if (Object.keys(payload).length === 0) {
      localStorage.removeItem(`smartFiltersAudits`)
    } else {
      localStorage[`smartFiltersAudits`] = JSON.stringify(payload)
    }
    await setFilterLinkType('smartFilter')
    await setFilterLinkText(payload)
  }

  async function resetSelectedConditions() {
    selectedConditions.value = {}
    await setFilterLinkType('')
    await setFilterLinkText({})
    setGlobalFilter('')
    localStorage.removeItem(`smartFiltersAudits`)
  }

  const whereQuery = ref({} as any)
  async function setAND(payload) {
    if (whereQuery.value.AND) {
      if (payload.auditDate) {
        whereQuery.value.AND.auditDate = undefined
      }
      payload = { ...whereQuery.value.AND, ...payload }
    }
    whereQuery.value.AND = payload
  }
  async function setOR(payload) {
    whereQuery.value.OR = payload
  }

  const sortQuery = ref({ createdAt: 'desc' })
  function setSortQuery(payload) { sortQuery.value = payload }

  const takeQuery = ref(auditsStore.auditsCalendarView ? 1000 : 15)
  function setTakeQuery(payload) { takeQuery.value = payload }

  const skipQuery = ref(0)
  function setSkipQuery(payload) { skipQuery.value = payload }

  const fetchQuery = ref({
    where: undefined as any,
    orderBy: sortQuery.value,
    take: takeQuery.value,
    skip: skipQuery.value,
  })

  async function setFetchQuery(payload: any) {
    const resetPagination = () => {
      setTakeQuery(auditsStore.auditsCalendarView ? 1000 : 15)
      setSkipQuery(0)
    }

    if (payload.orderBy?.order && payload.orderBy?.field) {
      const orderPayload = payload.orderBy
      let query = {}
      const order = orderPayload.order === 1 ? 'asc' : 'desc'
      const field = orderPayload.field === 'checks' ? 'status' : orderPayload.field
      if (orderPayload.field === 'group' || orderPayload.field === 'responsible') {
        query = { [field]: { name: order } }
      } else {
        query = { [field]: order }
      }
      setSortQuery(query)
      resetPagination()
    }

    if (payload.where) {
      const wherePayload = payload.where
      const otherQuery: any = {}
      let globalQuery: any = []
      if (wherePayload.global || wherePayload.global === '') {
        globalQuery = [
          { name: { contains: wherePayload.global, mode: 'insensitive' } },
          { tags: { hasSome: [wherePayload.global] } },
          { checks: { some: { name: { contains: wherePayload.global, mode: 'insensitive' } } } },
          { group: { name: { contains: wherePayload.global, mode: 'insensitive' } } },
          { responsible: { name: { contains: wherePayload.global, mode: 'insensitive' } } },
          { checks: { some: { location: { name: { contains: wherePayload.global, mode: 'insensitive' } } } } },
        ]
      }
      if (wherePayload.period) {
        const startDate = wherePayload.period?.start
          ? new Date(wherePayload.period.start).toJSON()
          : undefined
        const endDate = wherePayload.period?.end
          ? new Date(wherePayload.period.end).toJSON()
          : undefined
        otherQuery.auditDate = {
          gte: startDate,
          lte: endDate,
        }
      }
      if (wherePayload.smart) {
        if (Object.keys(wherePayload.smart).length) {
          if (wherePayload.smart.names) {
            otherQuery.name = { in: wherePayload.smart.names }
          }
          if (wherePayload.smart.groups) {
            otherQuery.group = { name: { in: wherePayload.smart.groups } }
          }
          if (wherePayload.smart.responsibles) {
            otherQuery.responsible = { name: { in: wherePayload.smart.responsibles } }
          }
          if (wherePayload.smart.statuses) {
            const numbersOfStatuses = wherePayload.smart.statuses.map(item => (item.status))
            otherQuery.status = { in: numbersOfStatuses }
          }
        } else {
          otherQuery.name = undefined
          otherQuery.group = undefined
          otherQuery.responsible = undefined
          otherQuery.status = undefined
        }
      }

      if (Object.keys(globalQuery).length !== 0) {
        await setOR(globalQuery)
      }

      if (otherQuery.length !== 0) {
        await setAND(otherQuery)
      }

      resetPagination()
    }

    if (payload.take) {
      setTakeQuery(payload.take)
    }

    if (payload?.skip || payload.skip === 0) {
      setSkipQuery(payload.skip)
    }

    fetchQuery.value = {
      where: whereQuery.value,
      orderBy: sortQuery.value,
      take: takeQuery.value,
      skip: skipQuery.value,
    }

    auditsStore.setIsLoaded(false)
    await auditsStore.load()
  }

  async function resetFetchQuery() {
    fetchQuery.value = {
      where: undefined as any,
      orderBy: {
        createdAt: 'desc',
      },
      take: auditsStore.auditsCalendarView ? 1000 : 15,
      skip: 0,
    }
  }

  const filterLinkText = ref('')
  async function setFilterLinkText(payload: any) {
    const copy = payload
    if (copy.statuses) {
      copy.statuses = copy.statuses.map(item => ({
        name: commonStore.$t.auditStatus[item],
        status: statuses[item].status,
      }))
    }
    filterLinkText.value = conditionsToString(copy)
  }

  const filterLinkType = ref('')
  async function setFilterLinkType(payload: string) {
    filterLinkType.value = payload
    localStorage[`filterLinkType`] = payload
  }

  async function smartFilterLoad(conditionsSF?: any) {
    if (conditionsSF) {
      await setSelectedConditions(conditionsSF)
    } else {
      await setSelectedConditions(JSON.parse(localStorage.smartFiltersAudits))
    }
    let takeCounter = auditsStore.auditsCalendarView ? 1000 : 15
    let skipCounter = 0
    if (localStorage.dt_state_audits) {
      const localData = JSON.parse(localStorage.dt_state_audits)
      takeCounter = localData.rows
      skipCounter = localData.first
    }
    await setFilterLinkType('smartFilter')
    await setFetchQuery({
      where: {
        smart: selectedConditions.value,
        period: undefined,
        global: '',
      },
      orderBy: {
        createdAt: 'desc',
      },
      take: takeCounter,
      skip: skipCounter,
    })
  }

  async function standartLoad(startDate?: any, endDate?: any) {
    if (localStorage.smartFiltersAudits) {
      await setSelectedConditions(JSON.parse(localStorage.smartFiltersAudits))
    }
    let takeCounter = auditsStore.auditsCalendarView ? 1000 : 15
    let skipCounter = 0
    if (localStorage.dt_state_audits && !auditsStore.auditsCalendarView) {
      const localData = JSON.parse(localStorage.dt_state_audits)
      takeCounter = localData.rows
      skipCounter = localData.first
    }
    await setFilterLinkType('')
    await resetFetchQuery()
    const periodQuery = {
      start: startDate,
      end: endDate,
    }
    await setFetchQuery({
      where: {
        smart: {},
        period: auditsStore.auditsCalendarView ? periodQuery : undefined,
        global: '',
      },
      orderBy: {
        createdAt: 'desc',
      },
      take: takeCounter,
      skip: skipCounter,
    })
  }

  return {
    statuses,
    fetchQuery, setFetchQuery, resetFetchQuery,
    conditions, loadConditions,
    selectedConditions, setSelectedConditions, resetSelectedConditions,
    filterLinkText, setFilterLinkText,
    filterLinkType, setFilterLinkType,
    globalFilter, setGlobalFilter,
    globalPeriod, setGlobalPeriod,
    smartFilterLoad, standartLoad,
  }
})
