import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'

const apiSlug = 'tenants'

export const useTenantsStore = defineStore(apiSlug, () => {
  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }

  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data?.data) {
      setData(response.data.data)
    }
    return response
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  const tenant = ref({})
  async function getCurrentTenant(id: any) {
    const response = await API.get(`${apiSlug}/${id}`)
    if (response?.data?.data) {
      tenant.value = response.data.data
    }
  }

  async function preSave(payload: any) {
    return {
      name: payload.name || undefined,
      license: payload.license || payload.license === '' ? payload.license : undefined,
      analyticsLink: payload.analyticsLink || undefined,
    }
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    await load()
  }

  async function createLicense(payload: any) {
    const preparedData = {
      auditsLimit: payload.auditsLimit || undefined,
      usersLimits: payload.usersLimits || undefined,
      expirationDate: payload.expirationDate || undefined,
      companyName: payload.companyName || undefined,
    }
    const response = await API.post(`licenses`, preparedData)
    return response
  }

  return {
    data, setData, isLoaded, setIsLoaded, load,
    create, save, remove, preSave, afterSuccessRequest,
    isShowModal, setShowModal,
    tenant, getCurrentTenant,
    createLicense, apiSlug,
  }
})
