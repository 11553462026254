export default {
    ru: {
        $lang: 'ru',

        primevue: {
            startsWith: 'Начинается с',
            contains: 'Содержит',
            notContains: 'Не содержит',
            endsWith: 'Заканчивается на',
            equals: 'Равно',
            notEquals: 'Не равно',
            noFilter: 'Без фильтра',
            lt: 'Меньше',
            lte: 'Меньше или равно',
            gt: 'Больше',
            gte: 'Больше или равно',
            dateIs: 'Дата равна',
            dateIsNot: 'Дата не равна',
            dateBefore: 'Раньше',
            dateAfter: 'Позднее',
            clear: 'Очистить',
            apply: 'Применить',
            matchAll: 'Совпадает полностью',
            matchAny: 'Совпадает с любым',
            addRule: 'Добавить правило',
            removeRule: 'Удалить правило',
            accept: 'Да',
            reject: 'Нет',
            choose: 'Выбрать',
            upload: 'Загрузить',
            cancel: 'Отменить',
            dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
            dayNamesShort: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
            dayNamesMin: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
            monthNames: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],
            monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн","Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
            today: 'Сегодня',
            weekHeader: 'НЕД',
            firstDayOfWeek: 1,
            dateFormat: 'dd.mm.yy',
            weak: 'Слабый',
            medium: 'Средний',
            strong: 'Сильный',
            passwordPrompt: 'Введите пароль',
            emptyFilterMessage: 'Результаты не найдены',
            emptyMessage: 'Нет доступных значений'
        },

        elements: {
            loading: 'Загрузка...',
            analyticsLink: 'Ссылка на Аналитику',
            license: 'Лицензия',
            noFiles: 'Нет загруженных файлов',
            audit: 'Аудит',
            description: 'Описание',
            fullName: 'Полное имя',
            phone: 'Телефон',
            name: 'Название',
            level: 'Уровень',
            title: 'Название',
            position: 'Должность',
            responsibilities: 'Обязанности',
            responsibleObjectGroups: 'Ответственный за группы объектов',
            responsible: 'Ответственный',
            checklists: 'Чеклисты',
            totalAudits: 'Всего проверок',
            tableTags: 'Теги',
            password: 'Пароль',
            address: 'Адрес',
            details: 'Детали',
            department: 'Отдел',
            tag: 'Тег',
            objectGroup: 'Группа объектов',
            finishDate: 'Дата завершения',
            date: 'Дата',
            noData: 'нет данных',
            noGroup: 'Без группы',
            photo: 'Фото профиля',
            userPhoto: 'Фото пользователя',
            userName: 'Имя',
            contactPerson: 'Контактное лицо',
            isEmpty: 'не заполнено',
            results: 'Результаты',
            comment: 'Комментарий',
            comments: 'Комментарии',
            category: 'Категория',
            categories: 'Категории',
            requirements: 'Требования',
            checks: 'Проверки',
            check: 'Проверка',
            in: 'в ',
            auditors: 'Аудиторы',
            auditor: 'Аудитор',
            object: 'Объект',
            objects: 'Объекты',
            planning: 'Планирование',
            more: 'еще',
            noElements: 'Элементов не найдено',
            noAuthor: 'Ответственный не указан',
            noDate: 'Дата не указана',
            dateCreate: 'Дата создания',
            creator: 'Создатель',
            locationInformation: 'Информация о местонахождении',
            startDate: 'Дата начала',
            endDate: 'Повторять до',
            dueDate: 'Плановая дата',
            tags: 'Теги',
            result: 'Результат',
            assigned: 'Дата создания',
            requirement: 'Требование',
            responsibleFor: 'Ответственный за',
            events: 'события',
            outOf: 'из',
            checkDate: 'Дата проверки',
            login: 'Логин',
            invalidCode: 'Ссылка на создание пароля неверна или устарела',
            files: 'Файлы',

            init: {
                tenantName: 'Имя Тенанта',
                userName: 'Имя Пользователя',
                userLogin: 'Логин Пользователя',
                validateError: 'не может быть пустым',
                userExist: 'Пользователь уже создан',
                created: 'Пользователь успешно создан',
            },

            smartFilter: {
                name: 'Умный Фильтр',
                cancel: 'Отменить',
                clear: 'Очистить фильтр',
                filter: 'Сохранить и применить',
                success: 'Умный Фильтр успешно применен',
                clearSuccess: 'Настройки Умного Фильта сброшены',
            },
        },

        actions: {
            filter: 'Фильтр',
            accept: 'Да',
            reject: 'Нет',
            import: 'Импортировать',
            export: 'Экспортировать',
            exportShort: 'Экспорт',
            reset: 'Сбросить',
            signIn: 'Войти',
            logout: 'Выйти',
            selectPosition: 'Выбрать должность',
            resetPassword: 'Сбросить пароль',
            changePassword: 'Изменить пароль',
            successResetPassword: 'Письмо с ссылкой для сброса пароля отправлено по указанному электронному адресу. Проверьте Вашу почту.',
            backToLogin: 'Авторизация',
            backToReset: 'Вернуться к сбросу пароля',
            confirmation: 'Подтверждение',
            edit: 'Изменить',
            search: 'Поиск',
            tags: 'Теги',
            save: 'Сохранить',
            add: 'Добавить',
            close: 'Закрыть',
            continue: 'Продолжить',
            changePosition: 'Изменить должность',
            changeColor: 'Изменить цвет',
            selectResponsibilities: 'Выбрать обязанности',
            selectResponsible: 'Выбрать ответственного',
            selectObjectGroup: 'Выбрать Группу Объектов',
            selectAuditor: 'Выбрать Аудитора',
            viewAudits: 'Просмотреть все аудиты',
            selectDepartment: 'Выбрать отдел',
            saveSuccess: 'Данные сохранены',
            newPasswordOnEmail: 'Ссылка для смены пароля отправлена на email',
            batchEdit: 'Редактирование',
            items: 'предметов',
            create: 'Создать',
            cancel: 'Отменить',
            selectGroup: 'Выбрать Группу',
            selectPlanning: 'Выбрать Планирование',
            switchToCalendar: 'Переключить на Календарь',
            switchToTable: 'Переключить на Таблицу',
            addComment: 'Добавить комментарий',
            creatingAudit: 'Происходит создание Аудита...',
            savingAudit: 'Происходит сохранение Аудита...',
            select: 'Выбрать',
            selectedObjects: 'Объектов выбрано',
            selectedAudits: 'Аудитов выбрано',
            selectedTasks: 'Задач выбрано',
            delete: 'Удалить',
            show: 'Показать',
            sending: 'Идет отправка данных, подождите...',
            showAll: 'Показать все',
            photoChanged: 'Фотография изменена',
            loading: 'Идет загрузка, подождите...',
            needAuth: 'Сессия устарела. Вам необходимо заново авторизоваться.',
            showHidden: 'Показать скрытые',
            hideHidden: 'Не показывать скрытые',
            restoreHidden: 'Убрать из скрытых',
            passwordReset: 'Пароль успешно сброшен',
            openTask: 'Открыть Задачу',
            addLicense: 'Создать лицензию',
            copyText: 'Скопировать',
            download: 'Скачать',
            successCreated: 'Пароль успешно создан, Вы можете авторизоваться',
            successChangedEmail: 'Email успешно изменен. Необходимо авторизоваться',
            errorChangedEmail: 'Ссылка устарела или неверна',
        },

        comments: {
            markedTask: 'пометил(а) задачу как',
            withComment: 'С комментарием',
            created: 'Созданную',
            pending: 'В ожидании',
            rejected: 'Отклонённую',
            done: 'Завершённую',
        },

        dublicates: {
            duplicatesFound: 'Найдены дубликаты',
            startImport: 'Начать импорт',
            cancelImport: 'Отменить импорт',
            noElements: 'Нет элементов для экспорта',
        },

        errors: {
            found: 'не найдены',
        },

        auditStatus: {
            all: 'Все Аудиты',
            pending: 'В ожидании',
            progress: 'В процессе',
            correct: 'Завершен корректно',
            noncomformities: 'Завершен с несоответствиями',
        },

        planning: {
            single: 'Один раз',
            day: 'Каждый день',
            week: 'Каждую неделю',
            month: 'Каждый месяц',
            year: 'Каждый год',
        },

        roles: {
            role: 'Роль',
            selectRole: 'Выбрать роль',
            admin: 'Администратор',
            manager: 'Менеджер',
            auditor: 'Аудитор',
            superAdmin: 'Главный Администратор',
        },

        taskStatuses: {
            all: 'Все Задачи',
            done: 'Завершено',
            pending: 'В ожидании',
            overdue: 'Просрочено',
            rejected: 'Отклонено',
        },

        validation: {
            password: {
                emptyPassword: 'Необходимо указать пароль',
                lengthPassword: 'Используйте 6 или более символов',
                useNumber: 'Используйте номер',
                differentPasswords: 'Пароли не совпадают',
                passwordPrompt: 'Введите пароль',
                confirmPassword: 'Подтвердите пароль',
                samePasswords: 'Новый пароль должен отличаться от старого',
                notSame: 'Подтверждение пароля должно совпадать с паролем',
            },
            name: {
                emptyName: 'Необходимо указать имя пользователя',
                emptyTagName: 'Необходимо указать название тега',
                emptyGroupName: 'Необходимо указать название группы',
                emptyCategoryName: 'Необходимо указать название категории',
                emptyChecklistName: 'Необходимо указать название чеклиста',
                responsibleEmpty: 'Необходимо выбрать ответственного',
                emptyAuditName: 'Необходимо указать название Аудита',
                emptyCheckName: 'Необходимо указать название Проверки',
                existCheckName: 'Проверка с таким названием уже существует',
                emptyObjectName: 'Необходимо указать имя Объекта',
                emptyRequirementName: 'Необходимо указать имя Требования',
                comment: 'Необходимо ввести комментарий',
                emptyTenantName: 'Необходимо указать имя Тенанта',
                companyName: 'Необходимо указать Имя Компании',
            },
            email: {
                emailEmpty: 'Адрес электронной почты не должен быть пустым',
                emailContain: 'Адрес электронной почты должен содержать "@" и домен',
                oldPasswordError: 'Не верный старый пароль',
            },
            phone: {
                phoneLengthShort: 'Слишком короткий номер телефона'
            },
            role: {
                roleEmpty: 'Необходимо выбрать роль пользователя'
            },
            category: {
                categoryEmpty: 'Необходимо выбрать категорию'
            },
            id: {
                emptyUserId: 'Не удалось добавить ID Пользователя',
                emptyTaskId: 'Не удалось добавить ID Задачи',
                emptyAuditId: 'Не удалось добавить ID Аудита',
                emptyItemId: 'Не удалось добавить ID Элемента',
            },
            responsible: {
                responsibleEmpty: 'Необходимо выбрать Ответственного',
                auditorEmpty: 'Необходимо выбрать Аудитора',
            },
            array: {
                checksEmpty: 'Аудит должен содержать минимум одну Проверку',
                requirementsEmpty: 'Чеклист должен содержать минимум одно Требование',
            },
            object: {
                objectEmpty: 'Необходимо выбрать Объект',
            },
            checklist: {
                checklistEmpty: 'Необходимо выбрать Чеклист',
            },
            date: {
                dateEmpty: 'Необходимо выбрать Дату',
            },
            license: {
                userLimit: 'Ваша лицензия не позволяет создавать больше пользователей.',
                auditsLimit: 'Ваша лицензия не позволяет создавать больше Аудитов',
            },
            taskEdit: {
                user: 'Пользователь отклонил задачу. Замените исполнителя',
                dueDate: 'Задача просрочена, измените срок выполнения',
            }
        },

        pages: {
            licenses: {
                companyName: 'Имя Компании',
                auditsLimit: 'Лимит Аудитов',
                usersLimits: 'Лимит Пользователей',
                expirationDate: 'Срок годности',
                licenseSuccess: 'Лицензия успешно создана',
                licenseCopied: 'Ключ лицензии успешно скопирован!',
                licenseKey: 'Ключ лицензии',
            },

            reset: {
                choosePassword: 'Введите новый пароль',
            },

            tenants: {
                title: 'Тенанты',
                confirmRemove: 'Вы уверены, что хотите удалить этого Тенанта?',
                edit: 'Изменить Тенант',
                createNew: 'Создать Тенант',
                saved: 'Тенант сохранен',
                removed: 'Тенант удален',
                company: 'Компания',
            },

            reports: {
                title: 'Отчеты'
            },

            checklists: {
                title: 'Чеклисты',
                createNewChecklist: 'Создать Чеклист',
                noChecklists: 'Чеклистов не найдено',
                selectChecklistCategory: 'Выберите категорию',
                selectChecklist: 'Выберите Чеклист',
                editChecklist: 'Изменить Чеклист',
                elementSaved: 'Чеклист сохранен',
                elementsSaved: 'Чеклисты сохранены',
                elementAdded: 'Чеклист добавлен',
                checklistsAdded: 'Чеклисты добавлены',
                confirmRemoveChecklist: 'Вы уверены, что хотите удалить Чеклист?',
                elementDeleted: 'Чеклист удален',
                elementsDeleted: 'Чеклисты удалены',
                allRequirements: 'Все требования',
                checklistRequirements: 'Требования Чеклиста',
                batch: 'чеклистов'
            },

            tasks: {
                title: 'Задачи',
                noTasks: 'Задач не найдено',
                editTask: 'Изменить Задачу',
                taskSaved: 'Задача Сохранена',
                confirmDelete: 'Вы уверены, что хотите удалить эту Задачу?',
                taskDeleted: 'Задача была удалена',
            },

            profile: {
                title: 'Профиль',
                editProfile: 'Изменить профиль',
                responsibleObjects: 'Ответственный за Объекты',
                responsibleAudits: 'Ответственный за Аудиты',
                responsibleTasks: 'Ответственный за Задачи',
                changePhoto: 'Изменить фото',
                loadPhoto: 'Загрузить фото',
                openPhoto: 'Открыть фото',
                removePhoto: 'Удалить фото',
                removePhotoConfirm: 'Вы уверены, что хотите удалить фото?',
                changeEmailConfirm: 'Вы уверены, что хотите изменить Email?',
                confirmChangeEmail: 'Для того, чтобы изменить адрес почты, необходимо перейти по ссылке, которая выслана Вам на указанный Email',
                waitingEmailSend: 'Идёт отправка письма на почту...',
            },

            editProfile: {
                title: 'Изменить профиль',
                personalInformation: 'Персональная информация',
                oldPassword: 'Старый пароль',
                newPassword: 'Новый пароль',
            },

            settings: {
                title: 'Настройки'
            },

            tags: {
                title: 'Теги',
                tags: 'Теги',
                editTag: 'Изменить тег',
                addTag: 'Добавить тег',
                tagAdded: 'Тег добавлен',
                tagSaved: 'Тег сохранен',
                tagRemoved: 'Тег удален',
                tagRemoveConfirm: 'Вы уверены, что хотите удалить этот тег?',
                tagAlreadyAdded: 'Такой тег уже добавлен',
            },

            locations: {
                title: 'Объекты',
                noObjects: 'Объектов не найдено',
                editObject: 'Изменить объект',
                newObject: 'Новый объект',
                elementAdded: 'Объект добавлен',
                elementsAdded: 'Объекты добавлены',
                elementSaved: 'Объект сохранен',
                elementsSaved: 'Объекты сохранены',
                elementDeleted: 'Объект удален',
                elementsDeleted: 'Объекты удалены',
                batch: 'объектов',
                allObjects: 'Все объекты',
                selectObject: 'Выбрать Объект',
                confirmDelete: 'Вы уверены, что хотите удалить Объект?',
            },

            audit: {
                title: 'Аудиты',
                noAudits: 'Аудитов не найдено',
                auditCreate: 'Создать Аудит',
                auditEdit: 'Изменить Аудит',
                createCheck: 'Создать Проверку',
                editCheck: 'Изменить Проверку',
                auditSaved: 'Аудит сохранен',
                confirmDelete: 'Вы уверены, что хотите удалить этот Аудит?',
                auditDeleted: 'Аудит был удален',
                changeCheckDueDate: 'При изменении Даты начала Аудита, поменялись Плановые даты у некоторых Проверок',
                loadingAudits: 'Идет загрузка Аудитов, пожалуйста подождите...',
                confirmCreate: 'Создать Аудит по выбранной дате?',
                disableDates: 'Вы не можете поменять даты у дочерних Аудитов.',
                withoutSaving: 'Изменения не будут сохранены.',
                auditsCreated: 'Создано Аудитов',
            },

            manage: {
                title: 'Настройки'
            },

            objectGroups: {
                title: 'Объекты и группы объектов',
                groupsTitle: 'Группы объектов',
                selectGroup: 'Выберите группу объектов',
                confirmRemoveObjectGroup: 'Вы уверены, что хотите удалить эту группу объектов?',
                objectsNum: ['Объект', 'Объекта', 'Объектов'],
                createNewObjectGroup: 'Создать новую группу объектов',
                editNewObjectGroup: 'Изменить группу объектов',
                uploadPicture: 'Загрузить изображение',
                removePicture: 'Удалить изображение',
                objectGroupSaved: 'Группа объектов сохранена',
                objectGroupAdded: 'Группа объектов добавлена',
                objectGroupRemoved: 'Группа объектов удалена',
            },

            checklistCategories: {
                title: 'Категории чеклистов',
                checklistsNum: ['Чеклист', 'Чеклиста', 'Чеклистов'],
                createNewCategory: 'Создать новую Категорию Чеклистов',
                editCategory: 'Изменить Категорию Чеклистов',
                categorySaved: 'Категория Чеклистов сохранена',
                categoryAdded: 'Категория Чеклистов добавлена',
                categoryRemoved: 'Категория Чеклистов удалена',
                confirmRemoveCategory: 'Вы уверены, что хотите удалить эту Категорию Чеклистов?',
            },

            requirements: {
                title: 'Требования',
                noRequirements: 'Требования не найдены',
                editRequirement: 'Изменить требование',
                newRequirement: 'Новое требование',
                elementAdded: 'Требование добавлено',
                elementsAdded: 'Требования добавлены',
                elementSaved: 'Требование сохранено',
                elementsSaved: 'Требования сохранены',
                elementRemoved: 'Требование удалено',
                elementsRemoved: 'Требования удалены',
                confirmRemoveRequirement: 'Вы уверены, что хотите удалить это Требование?',
                batch: 'требований',
            },

            templates: {
                title: 'Шаблоны аудита',
                saveTemplate: 'Сохранить как шаблон',
                selectTemplate: 'Выбрать шаблон аудита',
            },

            departments: {
                title: 'Отделы и должности',
                departments: 'Отделы',
                editDepartment: 'Изменить отдел',
                addDepartment: 'Добавить отдел',
                departmentAdded: 'Отдел добавлен',
                departmentSaved: 'Отдел сохранен',
                departmentRemoved: 'Отдел удален',
                departmentRemoveConfirm: 'Вы уверены, что хотите удалить этот отдел?',
                positionRemoveConfirm: 'Вы уверены, что хотите удалить эту должность?',
                positions: 'Должности',
                positionsNum: ['Должность', 'Должности', 'Должностей'],
                positionRemoved: 'Должность удалена',
                editPosition: 'Изменить должность',
                addPosition: 'Добавить должность',
                positionAdded: 'Должность добавлена',
                positionSaved: 'Должность сохранена',
                usersNum: ['Пользователь', 'Пользователя', 'Пользователей']
            },

            users: {
                title: 'Пользователи',
                noUsers: 'Пользователей не найдено',
                editUser: 'Изменить пользователя',
                addUser: 'Добавить пользователя',
                userDeleted: 'Пользователь удален',
                userAdded: 'Пользователь добавлен',
                userSaved: 'Письмо отправлено',
                or: 'или',
                confirmRemoveUser: 'Вы хотите удалить пользователя?',
                objectsResponsible: 'Данный пользователь является ответственным за объекты: ',
                checksResponsible: 'Данный пользователь является аудитором по чек-листам: ',
                tasksResponsible: 'Данный пользователь является исполнителем задач: ',
                auditsResponsible: 'Данный пользователь является исполнителем аудитов: ',
                usersAdded: 'Пользователи добавлены',
                importUserDublicate: 'В JSON файле нет уникальных Пользователей для импорта',
                userWithTasksValidation: 'Нельзя удалить Пользователя, потому что он является исполнителем Задач',
                usersCreated: 'Пользователей создано',
            },

            loginPage: {
                title: 'Вход в учетную запись',
                forgorPassword: 'Забыли пароль?',
                errorLogin: 'Не удалось выполнить вход. Попробуйте еще раз',
            },

            registrationPage: {
                title: 'Добро пожаловать!',
                choosePassword: 'Для продолжения регистрации выберите свой пароль',
                init: 'Установка',
            },

            searchPage: {
                title: 'Результат по запросу',
                viewAll: 'Все результаты'
            },

            checks: {
                confirmRemoveCheck: 'Вы уверены, что хотите удалить эту проверку?',
                successRemove: 'Проверка успешно удалена',
            }
        },

        paginator: {
            rowsPerPage: 'Строк на странице:'
        }
    },

    en: {
        $lang: 'en',

        primevue: {
            startsWith: 'Starts with',
            contains: 'Contains',
            notContains: 'Not contains',
            endsWith: 'Ends with',
            equals: 'Equals',
            notEquals: 'Not equals',
            noFilter: 'No Filter',
            lt: 'Less than',
            lte: 'Less than or equal to',
            gt: 'Greater than',
            gte: 'Greater than or equal to',
            dateIs: 'Date is',
            dateIsNot: 'Date is not',
            dateBefore: 'Date is before',
            dateAfter: 'Date is after',
            clear: 'Clear',
            apply: 'Apply',
            matchAll: 'Match All',
            matchAny: 'Match Any',
            addRule: 'Add Rule',
            removeRule: 'Remove Rule',
            accept: 'Yes',
            reject: 'No',
            choose: 'Choose',
            upload: 'Upload',
            cancel: 'Cancel',
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
            monthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
            monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            today: 'Today',
            weekHeader: 'Wk',
            firstDayOfWeek: 0,
            dateFormat: 'mm/dd/yy',
            weak: 'Weak',
            medium: 'Medium',
            strong: 'Strong',
            passwordPrompt: 'Enter a password',
            emptyFilterMessage: 'No results found',
            emptyMessage: 'No available options'
        },

        elements: {
            loading: 'Loading...',
            analyticsLink: 'Analytics Link',
            license: 'License',
            noFiles: 'No files',
            audit: 'Audit',
            description: 'Description',
            fullName: 'Full Name',
            phone: 'Phone',
            name: 'Name',
            level: 'Level',
            title: 'Title',
            position: 'Position',
            responsibilities: 'Responsibilities',
            responsibleObjectGroups: 'Responsible for Object Groups',
            responsible: 'Responsible',
            checklists: 'Checklists',
            totalAudits: 'Total Audits',
            tableTags: 'Tags',
            password: 'Password',
            address: 'Address',
            details: 'Details',
            department: 'Department',
            tag: 'Tag',
            objectGroup: 'Object Group',
            finishDate: 'Finish Date',
            date: 'Date',
            noData: 'no data',
            noGroup: 'No Group',
            photo: 'Profile Photo',
            userPhoto: "User's photo",
            userName: 'Name',
            contactPerson: 'Contact Person',
            isEmpty: 'is empty',
            results: 'Results',
            comment: 'Comment',
            comments: 'Comments',
            category: 'Category',
            categories: 'Categories',
            requirements: 'Requirements',
            checks: 'Checks',
            check: 'Check',
            in: 'in ',
            auditors: 'Auditors',
            auditor: 'Auditor',
            object: 'Object',
            objects: 'Objects',
            planning: 'Planning',
            more: 'more',
            noElements: 'Items not found',
            noAuthor: 'No Responsible',
            noDate: 'No Finish Date',
            dateCreate: 'Date created',
            creator: 'Creator',
            locationInformation: 'Location Information',
            startDate: 'Start Date',
            endDate: 'Repeat until',
            dueDate: 'Due Date',
            tags: 'Tags',
            result: 'Result',
            assigned: 'Assigned',
            requirement: 'Requirement',
            responsibleFor: 'Responsible for',
            events: 'events',
            outOf: 'out of',
            checkDate: 'Check Date',
            login: 'Login',
            invalidCode: 'The link to create a password is incorrect or out of date',
            files: 'Files',

            init: {
                tenantName: 'Tenant Name',
                userName: 'User Name',
                userLogin: 'User Login',
                validateError: 'cannot be empty',
                userExist: 'User already created',
                created: 'User successfully created',
            },

            smartFilter: {
                name: 'Smart Filter',
                cancel: 'Cancel',
                clear: 'Clear Filter',
                filter: 'Save and apply',
                success: 'Smart Filter successfully applied',
                clearSuccess: 'Smart Filter settings have been cleared',
            },
        },

        actions: {
            filter: 'Filter',
            accept: 'Yes',
            reject: 'No',
            import: 'Import',
            export: 'Export',
            exportShort: 'Export',
            reset: 'Reset',
            signIn: 'Sign-in',
            logout: 'Log Out',
            selectPosition: 'Select Position',
            resetPassword: 'Reset password',
            changePassword: 'Change password',
            successResetPassword: 'An Email with a password reset link has been sent to the specified Email address. Check your mail.',
            backToLogin: 'Back to login',
            backToReset: 'Back to reset',
            confirmation: 'Confirmation',
            edit: 'Edit',
            search: 'Search',
            tags: 'Tags',
            save: 'Save',
            add: 'Add new',
            close: 'Close',
            continue: 'Continue',
            changePosition: 'Change Position',
            changeColor: 'Change Color',
            selectResponsibilities: 'Select Responsibilities',
            selectResponsible: 'Select Responsible',
            selectObjectGroup: 'Select Object Group',
            selectAuditor: 'Select Auditor',
            viewAudits: 'View All Audits',
            selectDepartment: 'Select Department',
            saveSuccess: 'Data saved',
            newPasswordOnEmail: 'A link to change your password has been sent to your email',
            batchEdit: 'Batch Edit',
            items: 'Items',
            create: 'Create',
            cancel: 'Cancel',
            selectGroup: 'Select Group',
            selectPlanning: 'Select Planning',
            switchToCalendar: 'Switch to Calendar Veiw',
            switchToTable: 'Switch to Table Veiw',
            addComment: 'Add a comment',
            creatingAudit: 'Create Audit in progress...',
            savingAudit: 'Save Audit in progress...',
            select: 'Select',
            selectedObjects: 'Selected Objects',
            selectedAudits: 'Selected Audits',
            selectedTasks: 'Selected Tasks',
            delete: 'Delete',
            show: 'Show',
            sending: 'Sending data, please wait...',
            showAll: 'Show All',
            photoChanged: 'Photo modified',
            loading: 'Loading...',
            needAuth: 'The session has expired. You need to re-login.',
            showHidden: 'Show hidden',
            hideHidden: 'Don`t show hidden',
            restoreHidden: 'Remove from hidden',
            passwordReset: 'Password reset successfully',
            openTask: 'Open Task',
            addLicense: 'Create License',
            copyText: 'Copy',
            download: 'Download',
            successCreated: 'Password successfully created, You can log in',
            successChangedEmail: 'Email successfully changed. Need to login again',
            errorChangedEmail: 'Link is outdated or incorrect',
        },

        comments: {
            markedTask: 'marked the issue as',
            withComment: 'With comment',
            created: 'Created',
            pending: 'Pending',
            rejected: 'Rejected',
            done: 'Done',
        },

        dublicates: {
            duplicatesFound: 'Duplicates found',
            startImport: 'Start Import',
            cancelImport: 'Cancel Import',
            noElements: 'No elements to export',
        },

        errors: {
            found: 'not found',
        },

        auditStatus: {
            all: 'All Audits',
            pending: 'Pending',
            progress: 'In Progress',
            correct: 'Complete Correct',
            noncomformities: 'Complete with Noncomformities',
        },

        planning: {
            single: 'Single',
            day: 'Every day',
            week: 'Every week',
            month: 'Every month',
            year: 'Every Year',
        },

        roles: {
            role: 'Role',
            selectRole: 'Select Role',
            admin: 'Administrator',
            manager: 'Manager',
            auditor: 'Auditor',
            superAdmin: 'Super Admin',
        },

        taskStatuses: {
            all: 'All Tasks',
            done: 'Done',
            pending: 'Pending',
            overdue: 'Overdue',
            rejected: 'Rejected',
        },

        validation: {
            password: {
                emptyPassword: 'Password should not be empty',
                lengthPassword: 'Use 6 or more characters',
                useNumber: 'Use a number',
                differentPasswords: 'Passwords are different',
                passwordPrompt: 'Enter a password',
                confirmPassword: 'Confirm Password',
                samePasswords: 'New password must be different than current password',
                notSame: 'Password confirmation must match the password',
            },
            name: {
                emptyName: 'User Name should not be empty',
                emptyTagName: 'Tag Name should not be empty',
                emptyGroupName: 'Group Name should not be empty',
                emptyCategoryName: 'Category Name should not be empty',
                emptyChecklistName: 'Checklist Name should not be empty',
                responsibleEmpty: 'Responsible should not be empty',
                emptyAuditName: 'Audit Name should not be empty',
                emptyCheckName: 'Check Name should not be empty',
                existCheckName: 'Check with this Name is already exist',
                emptyObjectName: 'Object Name should not be empty',
                emptyRequirementName: 'Requirement Name should not be empty',
                comment: 'Comment text should not be empty',
                emptyTenantName: 'Tenant Name should not be empty',
                companyName: 'Company Name should not be empty',
            },
            email: {
                emailEmpty: 'Email should not be empty and must contain "@"',
                emailContain: 'Email must contain "@" and domain',
                oldPasswordError: 'Old password is not correct',
            },
            phone: {
                phoneLengthShort: 'Phone number too short'
            },
            role: {
                roleEmpty: 'Select user role'
            },
            category: {
                categoryEmpty: 'Select category'
            },
            id: {
                emptyUserId: 'User ID should not be empty',
                emptyTaskId: 'Task ID should not be empty',
                emptyAuditId: 'Audit ID should not be empty',
                emptyItemId: 'Item ID should not be empty',
            },
            responsible: {
                responsibleEmpty: 'Responsible should not be empty',
                auditorEmpty: 'Auditor should not be empty',
            },
            array: {
                checksEmpty: 'Audit must contain at least one Check',
                requirementsEmpty: 'Checklist must contain at least one Requirement',
            },
            object: {
                objectEmpty: 'Object should not be empty',
            },
            checklist: {
                checklistEmpty: 'Checklist should not be empty',
            },
            date: {
                dateEmpty: 'Date should not be empty',
            },
            license: {
                userLimit: 'Your license does not allow you to create more Users.',
                auditsLimit: 'Your license does not allow you to create more Audits',
            },
            taskEdit: {
                user: 'User has rejected the task. Change Responsible',
                dueDate: 'Task is overdue, change the Due Date',
            }
        },

        pages: {
            licenses: {
                companyName: 'Company Name',
                auditsLimit: 'Audits Limit',
                usersLimits: 'Users Limits',
                expirationDate: 'Expiration Date',
                licenseSuccess: 'License created successfully',
                licenseCopied: 'License copied successfully!',
                licenseKey: 'License Key',
            },

            reset: {
                choosePassword: 'Please choose new password',
            },

            tenants: {
                title: 'Tenants',
                confirmRemove: 'Do you want to delete this Tenant?',
                edit: 'Edit Tenant',
                createNew: 'Create new Tenant',
                saved: 'Tenant saved',
                removed: 'Tenant removed',
                company: 'Company',
            },

            reports: {
                title: 'Reports'
            },

            checklists: {
                title: 'Checklists',
                createNewChecklist: 'Create New Checklist',
                noChecklists: 'No checklists found',
                selectChecklistCategory: 'Select Category',
                selectChecklist: 'Select Checklist',
                editChecklist: 'Edit Checklist',
                elementSaved: 'Checklist Saved',
                elementsSaved: 'Checklists Saved',
                elementAdded: 'Checklist Added',
                checklistsAdded: 'Checklists Added',
                confirmRemoveChecklist: 'Do you want to delete this Checklist?',
                elementDeleted: 'Checklist Removed',
                elementsDeleted: 'Checklists Removed',
                allRequirements: 'All requirements',
                checklistRequirements: 'Checklist requirements',
                batch: 'checklists'
            },

            tasks: {
                title: 'Tasks',
                noTasks: 'No tasks found',
                editTask: 'Edit Task',
                taskSaved: 'Task Saved',
                confirmDelete: 'Do you want to delete a Task?',
                taskDeleted: 'Task was deleted',
            },

            profile: {
                title: 'Profile',
                editProfile: 'Edit Profile',
                responsibleObjects: 'Responsible for Objects',
                responsibleAudits: 'Responsible for Audits',
                responsibleTasks: 'Responsible for Tasks',
                changePhoto: 'Change photo',
                loadPhoto: 'Load photo',
                openPhoto: 'Open photo',
                removePhoto: 'Remove photo',
                removePhotoConfirm: 'Are you sure you want to remove photo?',
                changeEmailConfirm: 'Are you sure you want to change Email?',
                confirmChangeEmail: 'A link to change has been sent to your Email',
                waitingEmailSend: 'Email is being sent...',
            },

            editProfile: {
                title: 'Edit Profile',
                personalInformation: 'Personal Information',
                oldPassword: 'Old Password',
                newPassword: 'New Password',
            },

            settings: {
                title: 'Settings'
            },

            tags: {
                title: 'Tags',
                tags: 'Tags',
                editTag: 'Edit Tag',
                addTag: 'Add Tag',
                tagAdded: 'Tag Added',
                tagSaved: 'Tag Saved',
                tagRemoved: 'Tag Removed',
                tagRemoveConfirm: 'Do you want to delete a tag?',
                tagAlreadyAdded: 'This tag has already been added',
            },

            locations: {
                title: 'Objects',
                noObjects: 'No objects found',
                editObject: 'Edit Object',
                newObject: 'New Object',
                elementAdded: 'Object added',
                elementsAdded: 'Objects added',
                elementSaved: 'Object saved',
                elementsSaved: 'Objects saved',
                elementDeleted: 'Object deleted',
                elementsDeleted: 'Objects deleted',
                batch: 'objects',
                allObjects: 'All Objects',
                selectObject: 'Select Object',
                confirmDelete: 'Do you want to delete an Object?',
            },

            audit: {
                title: 'Audits',
                noAudits: 'No audits found',
                auditCreate: 'Create New Audit',
                auditEdit: 'Edit Audit',
                createCheck: 'Create Check',
                editCheck: 'Edit Check',
                auditSaved: 'Audit Saved',
                confirmDelete: 'Do you want to delete an Audit?',
                auditDeleted: 'Audit was deleted',
                changeCheckDueDate: 'When the Audit Start Date is changed, the Due Dates for some Checks have changed',
                loadingAudits: 'Loading Audits, please wait...',
                confirmCreate: 'Create a new Audit on a selected date?',
                disableDates: 'You cannot change dates for child Audits.',
                withoutSaving: 'Changes will not be saved.',
                auditsCreated: 'Audits created',
            },

            manage: {
                title: 'Manage'
            },

            objectGroups: {
                title: 'Objects & Object Groups',
                groupsTitle: 'Object Groups',
                selectGroup: 'Select Objects Group',
                confirmRemoveObjectGroup: 'Do you want to delete this group of objects?',
                objectsNum: ['Object', 'Objects', 'Objects'],
                createNewObjectGroup: 'Create New Object Group',
                editNewObjectGroup: 'Edit Object Group',
                uploadPicture: 'Upload picture',
                removePicture: 'Remove picture',
                objectGroupSaved: 'Objects Group Saved',
                objectGroupAdded: 'Objects Group Added',
                objectGroupRemoved: 'Objects Group Removed',
            },

            checklistCategories: {
                title: 'Checklist Categories',
                checklistsNum: ['Checklist', 'Checklists', 'Checklists'],
                createNewCategory: 'Create New Checklist Category',
                editCategory: 'Edit Checklist Category',
                categorySaved: 'Checklist Category Saved',
                categoryAdded: 'Checklist Category Added',
                categoryRemoved: 'Checklist Category Removed',
                confirmRemoveCategory: 'Do you want to delete this Checklist Category?',
            },

            requirements: {
                title: 'Requirements',
                noRequirements: 'Requirements Not Found',
                editRequirement: 'Edit Requirement',
                newRequirement: 'New Requirements',
                elementAdded: 'Requirement Added',
                elementsAdded: 'Requirements Added',
                elementSaved: 'Requirement Saved',
                elementsSaved: 'Requirements Saved',
                elementRemoved: 'Requirement Removed',
                elementsRemoved: 'Requirements Removed',
                confirmRemoveRequirement: 'Do you want to delete this Requirement?',
                batch: 'Requirements',
            },

            templates: {
                title: 'Audits Templates',
                saveTemplate: 'Save as template',
                selectTemplate: 'Select Template',
            },

            departments: {
                title: 'Departments & Positions',
                departments: 'Departments',
                editDepartment: 'Edit Department',
                addDepartment: 'Add Department',
                departmentAdded: 'Department Added',
                departmentSaved: 'Department Saved',
                departmentRemoved: 'Department Removed',
                departmentRemoveConfirm: 'Do you want to delete a department?',
                positionRemoveConfirm: 'Do you want to delete a position?',
                positions: 'Positions',
                positionsNum: ['Position', 'Positions', 'Positions'],
                positionRemoved: 'Position Removed',
                editPosition: 'Edit Position',
                addPosition: 'Add Position',
                positionAdded: 'Position Added',
                positionSaved: 'Position Saved',
                usersNum: ['User', 'Users', 'Users']
            },

            users: {
                title: 'Users',
                noUsers: 'No users found.',
                editUser: 'Edit User',
                addUser: 'Add User',
                userDeleted: 'User Deleted',
                userAdded: 'User Added',
                userSaved: 'Email has been sent',
                or: 'or',
                confirmRemoveUser: 'Do you want to delete this user?',
                objectsResponsible: 'This user is responsible for the Objects: ',
                checksResponsible: 'This user is an auditor for the Checklists: ',
                tasksResponsible: 'This user is the executor of the Tasks: ',
                auditsResponsible: 'This user is the executor of the Audits: ',
                usersAdded: 'Users Added',
                importUserDublicate: 'JSON file has no unique Users to import',
                userWithTasksValidation: 'You cannot delete a User because he is Responsible of the Tasks',
                usersCreated: 'Users created',
            },

            loginPage: {
                title: 'Account sign-in',
                forgorPassword: 'Forgot Password?',
                errorLogin: 'Failed to sign in. Try again',
            },

            registrationPage: {
                title: 'Welcome!',
                choosePassword: 'To proceed with registration please choose your password',
                init: 'Installation',
            },

            searchPage: {
                title: 'Results for',
                viewAll: 'View all results'
            },

            checks: {
                confirmRemoveCheck: 'Do you want to delete this Check?',
                successRemove: 'Check successfully removed',
            }
        },

        paginator: {
            rowsPerPage: 'Rows per page:'
        }
    }
}

