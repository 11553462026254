import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'

const apiSlug = 'tags'

export const useTagsStore = defineStore(apiSlug, () => {
  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    setIsLoaded(true)
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }

  async function fetchAll() {
    const response = await API.get(apiSlug)
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }

    const { data } = response.data;

    return data.map(item => item.name)
  }

  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data?.data) {
      const result: any[] = []
      for (const element of response.data.data) {
        result.push(element)
      }
      setData(result)
    }
    return response
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  async function color(name: string) {
    const obj = data.value.find((element: any) => element.name.trim().toLowerCase() === name.trim().toLowerCase())
    return obj ? obj.color : 'white'
  }

  async function selectTag(name, entity) {
    if (!entity.tagObjects) {
      entity.tagObjects = []
    }
    entity.tagObjects = entity.tagObjects.filter((t) => t.name !== '')
    const colorResult = await color(name)
    entity.tagObjects.push({
      name: name.toLowerCase(),
      color: colorResult,
    })
    entity.tags = entity.tagObjects?.map((x) => x.name).join(',')
  }

  async function getIntersectionTags(payload: any) {
    const tags = [] as any
    if (payload.length === 0) {
      return tags
    }
    const uniqueTags = payload.map(item => item.tags).reduce((a, c) => a.filter(i => c.includes(i)))
    if (uniqueTags.length) {
      for (const tag of uniqueTags) {
        const colorResult = await color(tag)
        tags.push({
          name: tag,
          color: colorResult,
        })
      }
    }
    return tags
  }

  async function setTagObjects(obj: any) {
    if (obj.tags) {
      await load()
      obj.tagObjects = []
      for (const tag of obj.tags) {
        const colorResult = await color(tag)
        obj.tagObjects.push({
          name: tag.toLowerCase(),
          color: colorResult,
        })
      }
    }
  }

  function prepareTags(payload: any) {
    return payload.length ? payload.map((tag: any) => tag.name.toLowerCase()) : []
  }

  async function preSave(payload: any) {
    payload.name = payload.name.toLowerCase()
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id: any) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    await load()
  }

  return {
    data, load, isLoaded, setIsLoaded, color,
    save, create, remove, afterSuccessRequest, setData,
    isShowModal, setShowModal,
    selectTag, setTagObjects, prepareTags, getIntersectionTags,
    apiSlug, preSave, fetchAll
  }
})
