<template>
  <div
    v-if="commonStore.isAuth"
    class="main-aside left-menu"
    :class="{ expanded: commonStore.expandMenu }"
  >
    <template v-for="item of menu" :key="item.label">
      <router-link
        v-if="authStore.isAdmin || (!authStore.isAdmin && item.to !== '/manage')"
        :to="item.to"
        exact
        role="menuitem"
        class="main-menu"
        :class="item.class"
        v-tooltip.right="{
          value: item.label,
          disabled: commonStore.expandMenu,
          escape: true,
        }"
      >
        <div class="icon-wrapper">
          <i :class="item.icon"></i>
        </div>
        <div class="menu-text-wrapper">
          <div class="menu-text">{{ item.label }}</div>
        </div>
      </router-link>
    </template>
    <div v-if="authStore.isAdmin" class="manage-menu mt-3">
      <template v-for="item of manage" :key="item.label">
        <router-link
          v-if="item.class !== 'tenants'"
          :to="item.to"
          exact
          role="menuitem"
          :class="item.class"
        >
          <div>
            <div class="menu-text">
              {{ item.label }}
            </div>
          </div>
        </router-link>
        <router-link
          v-if="item.class === 'tenants' && authStore.isSuperAdmin"
          :to="item.to"
          exact
          role="menuitem"
          :class="item.class"
        >
          <div>
            <div class="menu-text">
              {{ item.label }}
            </div>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useCommonStore } from '@/stores/common'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'

const route = useRoute()

const authStore = useAuthStore()
const commonStore = useCommonStore()

const menu = computed(() => [
  {
    label: commonStore.$t.pages.reports.title,
    icon: "mo-icon-reports",
    to: "/",
    class: "first",
  },
  {
    label: commonStore.$t.pages.locations.title,
    icon: "mo-icon-objects",
    to: "/objects",
    class: "",
  },
  {
    label: commonStore.$t.pages.checklists.title,
    icon: "mo-icon-checklists",
    to: "/checklists",
    class: "",
  },
  {
    label: commonStore.$t.pages.audit.title,
    icon: "mo-icon-audits",
    to: "/audit",
    class: "",
  },
  {
    label: commonStore.$t.pages.tasks.title,
    icon: "mo-icon-tasks",
    to: "/tasks",
    class: "",
  },
  {
    label: commonStore.$t.pages.manage.title,
    icon: "mo-icon-settings",
    to: "/manage",
    class: "last",
  },
])

const manage = computed(() => [
  {
    label: commonStore.$t.pages.tenants.title,
    to: "/manage/tenants",
    class: "tenants",
  },
  {
    label: commonStore.$t.pages.objectGroups.title,
    to: "/manage/objects",
    class: "",
  },
  {
    label: commonStore.$t.pages.checklistCategories.title,
    to: "/manage/categories",
    class: "",
  },
  {
    label: commonStore.$t.pages.requirements.title,
    to: "/manage/requirements",
    class: "",
  },
  {
    label: commonStore.$t.pages.departments.title,
    to: "/manage/departments",
    class: "",
  },
  {
    label: commonStore.$t.pages.users.title,
    to: "/manage/users",
    class: "",
  },
  {
    label: commonStore.$t.pages.tags.title,
    to: "/manage/tags",
    class: "",
  },
])
</script>
