import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { usePositionsStore } from './positions'
import { useUsersStore } from './users'

const apiSlug = 'departments'

export const useDepartmentsStore = defineStore(apiSlug, () => {
  const positionsStore = usePositionsStore()
  const usersStore = useUsersStore()

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data) {
      const result: any[] = []
      for (const element of response.data) {
        result.push(element)
      }
      setData(result)
    }
    return response
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  async function getById(id) {
    const response = await API.get(`${apiSlug}/${id}`)
    if (response?.data) {
      return response.data
    }
    return response
  }

  async function preSave(payload) {
    delete payload.id
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    positionsStore.setIsLoaded(false)
    usersStore.setIsLoaded(false)
    await load()
  }

  return {
    data, load, isLoaded, setIsLoaded, setData,
    isShowModal, setShowModal,
    getById, preSave, afterSuccessRequest,
    save, create, remove,
    apiSlug,
  }
})
