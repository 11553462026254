<template>
  <div>
    <div class="main-header p-nav">
      <div class="main-header__logo flex align-items-center">
        <i
          v-if="commonStore.isAuth"
          class="pi pi-bars mr-3"
          @click="leftMenu"
        />
        <router-link to="/">
          <div class="logo"></div>
        </router-link>
        <div style="color: white" class="my-3 mx-3">
          {{ authStore.data?.client?.name }}
        </div>
      </div>
      <div class="main-header__version" style="color: white;">
        version {{ config.version }}
        {{authStore.data?.client?.error ? authStore.data?.client?.error[commonStore.$t.$lang] : ''}}
      </div>
      <div class="main-header__menu flex align-items-center">
        <SearchModal v-if="commonStore.isAuth" />
        <div
          class="icon-language relative"
          @click="openLangMenu"
          aria:haspopup="true"
          aria-controls="overlay_panel"
        />
        <div class="search-mobile" v-if="commonStore.isAuth">
          <i class="pi pi-search" @click="openMobileSearch" />
        </div>
        <div
          v-if="commonStore.isAuth"
          class="header-avatar ml-6"
          @click="openUserMenu"
          aria:haspopup="true"
          aria-controls="overlay_panel2"
          :style="{ 'background-color': `#${authStore.data.color}` }"
        >
          <img
            v-if="authStore.data.profilePhoto"
            :src="`${authStore.data.profilePhoto}`"
            class="avatar-small"
            alt=""
          />
          <span v-else class="avatar-initials">
            {{ initials(authStore.data.name) }}
          </span>
        </div>
      </div>
    </div>
    <OverlayPanel
      ref="mobileSearchModal"
      appendTo="body"
      id="mobileSearch"
      class="header-overlay"
    >
      <div class="topMenu mobileSearch">
        <SearchModal />
      </div>
    </OverlayPanel>
    <OverlayPanel
      ref="langMenuModal"
      appendTo="body"
      id="overlay_panel"
      class="header-overlay"
    >
      <div class="topMenu langMenu">
        <a
          v-for="lang in languages"
          :key="lang"
          class="topMenu__link"
          @click="selectLanguage(lang)"
        >
          {{ lang.name }}
        </a>
      </div>
    </OverlayPanel>
    <OverlayPanel
      v-if="commonStore.isAuth"
      ref="userMenuModal"
      appendTo="body"
      id="overlay_panel2"
      class="header-overlay"
    >
      <div class="topMenu userMenu">
        <router-link
          to="/profile"
          exact
          role="menuitem"
          class="topMenu__link"
          @click="clickMenu"
        >
          <div class="menu-text">
            {{ commonStore.$t.pages.profile.title }}
          </div>
        </router-link>
        <router-link
          to="/profile-edit"
          exact
          role="menuitem"
          @click="clickMenu"
          class="topMenu__link"
        >
          <div class="menu-text">
            {{ commonStore.$t.pages.editProfile.title }}
          </div>
        </router-link>
        <router-link
          v-if="authStore?.featureFlags?.isCloud === false && authStore.isAdmin"
          to="/company"
          exact
          role="menuitem"
          @click="clickMenu"
          class="topMenu__link"
        >
          <div class="menu-text">
            {{ commonStore.$t.pages.tenants.company }}
          </div>
        </router-link>
        <router-link
          to="/login"
          exact
          role="menuitem"
          class="p-link font-16 topMenu__link"
          @click.stop="logout"
        >
          {{ commonStore.$t.actions.logout }}
        </router-link>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
import locales from '@/common/locales'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { initials } from '@/common/utils'
import config from '@/config'
import { useCommonStore } from '@/stores/common'
import router from '@/router'
import SearchModal from '@/components/searchModal'

import { useAuthStore } from '@/stores/auth'
import { useLogoutStore } from '@/stores/logout'

const authStore = useAuthStore()
const logoutStore = useLogoutStore()
const commonStore = useCommonStore()

let langMenu = ref(false)
let userMenu = ref(false)
const mobileSearch = ref(false)

const route = useRoute()

const langMenuModal = ref()
const userMenuModal = ref()
const mobileSearchModal = ref()

const lang = ref()

const languages = [
  { code: "ru", name: "Русский" },
  { code: "en", name: "English" },
]

const profilePhoto = ref('')

const selectLanguage = (lang) => {
  commonStore.setLang(lang)
  openLangMenu()
}

const clickMenu = () => {
  langMenu.value = false
  userMenu.value = false
  openUserMenu()
}

const openMobileSearch = event => {
  mobileSearchModal.value.toggle(event)
}

const leftMenu = evt => {
  commonStore.setExpandMenu(!commonStore.expandMenu)
}

const openLangMenu = event => {
  langMenuModal.value.toggle(event)
}

const openUserMenu = event => {
  userMenuModal.value.toggle(event)
}

const logout = async () => {
  await logoutStore.logout()
}
</script>
