<template>
  <div v-if="searchStore.searchQuery.length" class="topMenu search-modal">
    <Search
      :found="found"
      :highlighted="true"
    />
    <div
      v-if="searchStore.searchQuery !== '' && Object.keys(found).length > 0"
      class="search-result-footer mt-4 font-14"
    >
      <router-link :to="{ name: 'search' }" class="p-link">
        {{ commonStore.$t.pages.searchPage.viewAll }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { useRoute } from "vue-router"
import { useSearchStore } from '@/stores/search'
import Search from "@/components/search"
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()
const searchStore = useSearchStore()
const route = useRoute()
const found = ref({})

watch(() => searchStore.searchQuery, async () => {
  const response = await searchStore.search(searchStore.searchQuery, 7)
  found.value = Object.keys(response).length !== 0 ? response : {}
})
</script>
