import moment from 'moment'

const fixTimeZoneFormat = (value) => {
  if (!value) return null
  const inputDate = new Date(value)
  const hoursDiff = inputDate.getHours() - inputDate.getTimezoneOffset() / 60
  const minutesDiff = (inputDate.getHours() - inputDate.getTimezoneOffset()) % 60
  inputDate.setHours(hoursDiff)
  inputDate.setMinutes(minutesDiff)
  return inputDate
}

function dateFormat(st: string = '') {
  if (!st) return ''
  return moment(new Date(st)).format('DD.MM.YYYY')
}

function timeFormat(st: string = '') {
  if (!st) return ''
  return moment(new Date(st)).format('HH:mm')
}

function initials(str: string = '') {
  return str.split(/\s+/).map((w: string, i: number) => i < 2 ? w.substring(0, 1).toUpperCase() : '').join('')
}

function numWords(num: number, words: string) {
  return words[
    num % 100 > 4 && num % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][num % 10 < 5 ? Math.abs(num) % 10 : 5]
  ]
}

function randomColor(num: number, words: string) {
  return Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')
}

const downloadBlob = async (blob, name) => {
  const a = document.createElement('a')
  a.href = blob
  a.download = name
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function debounce(func, timeout = 350) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args)
    }, timeout)
  }
}

const conditionsToString = (data) => {
  let result = ''
  if (Object.keys(data).length) {
    let step = 0
    const copy = Object.values(data).filter((item: any) => Object.keys(item).length !== 0)
    copy.map((condition: any) => {
      const statuses = [] as any
      Object.values(condition).forEach((value: any) => {
        if (value.name) { statuses.push(value.name) }
      })
      result = result + (statuses.length ? statuses.join(', ') : condition.join(', '))
      step++
      if (step !== copy.length) { result = result + ' | ' }
    })
  }
  return result
}

const transformYupErrorsIntoObject = (error: any) => {
  return error.path !== undefined ? error.errors[0] : error
}

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email) ? email : ''
}

const validatePhone = (phone) => {
  const onlyDigits = phone.replace(/[^\d+]/g, '')
  if (onlyDigits.length < 12) phone = ''
  if (onlyDigits.length === 12 && phone[1] !== '7') {
    phone = phone.replace(`+${phone[1]}`, `+7`)
  }
  return phone
}

export {
  fixTimeZoneFormat,
  dateFormat,
  timeFormat,
  initials,
  numWords,
  randomColor,
  downloadBlob,
  debounce,
  conditionsToString,
  transformYupErrorsIntoObject,
  validateEmail,
  validatePhone,
}
