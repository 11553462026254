import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { fixTimeZoneFormat } from '@/common/utils'
import { useLocationsStore } from './locations'
import { useTagsStore } from './tags'
import { useSfAuditsStore } from './smartFilterAudits'
import { useCommonStore } from '@/stores/common'
import { useAuthStore } from '@/stores/auth'

const apiSlug = 'audits'

export const useAuditsStore = defineStore(apiSlug, () => {
  const locationsStore = useLocationsStore()
  const tagsStore = useTagsStore()
  const sfAuditsStore = useSfAuditsStore()
  const commonStore = useCommonStore()
  const authStore = useAuthStore()

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) { isLoaded.value = value }

  const totalRecords = ref(0)
  function setTotalRecords(count: number) {
    if (totalRecords.value === count) { return }
    totalRecords.value = count
  }

  const auditsCalendarView = ref(false)
  function setAuditsCalendarView(value: boolean) {
    auditsCalendarView.value = value
  }

  const isShowSidebar = ref(false)
  function setShowSidebar(payload: boolean) { isShowSidebar.value = payload }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) { isShowModal.value = payload }

  const isTableLoading = ref(true)
  function setTableLoading(payload: boolean) { isTableLoading.value = payload }

  const isCalendarLoading = ref(true)
  async function setCalendarLoading(payload: boolean) { isCalendarLoading.value = payload }

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
    setTableLoading(false)
  }

  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    setCalendarLoading(true)
    const response = await API.post(`${apiSlug}/fetch`, sfAuditsStore.fetchQuery)
    if (response?.data?.data) {
      setTotalRecords(response.data.total)
      const result: any[] = []
      for (const element of response.data.data) {
        result.push(await afterLoad(element))
      }
      setData(result)
    }
    setTableLoading(false)
    setCalendarLoading(false)
    return response
  }

  async function getUniqUsers(payload: any) {
    const auditors = [...new Set(payload.map((check) => JSON.stringify(check.auditor)))]
    return auditors.map((item: any) => {
      if (item) {
        return JSON.parse(item)
      }
    })
  }

  async function afterLoad(obj: any) {
    if (obj.auditDate) {
      obj.auditDate = fixTimeZoneFormat(obj.auditDate)
    }
    if (obj.periodEndDate) {
      obj.periodEndDate = fixTimeZoneFormat(obj.periodEndDate)
    }
    if (obj.tags) {
      await tagsStore.setTagObjects(obj)
    }
    obj.status = getStatusInfo(obj.status)
    if (obj.checks && obj.checks.length !== 0) {
      obj.auditors = await getUniqUsers(obj.checks)
      obj.checksStatusesSumm = obj.checks.reduce((prev, curr) => {
        return prev + curr.status
      }, 0)
    }
    if (auditsCalendarView.value) {
      obj.title = obj.name
      if (obj.auditDate) {
        const startDate = new Date(obj.auditDate)
        obj.start = `${startDate.getFullYear()}-${addZeroToDate(startDate.getMonth()+1)}-${addZeroToDate(startDate.getDate())}`
      }
      if (obj.finishDate) {
        const finishDate = new Date(obj.finishDate)
        obj.end = `${finishDate.getFullYear()}-${addZeroToDate(finishDate.getMonth()+1)}-${addZeroToDate(finishDate.getDate()+1)}`
      }
      obj.color = obj.status?.color
      obj.textColor = obj.status?.color === '#D24B64' ? '#fff' : '#222'
    }
    return obj
  }

  async function getAll() {
    const response = await API.get(`${apiSlug}`)
    if (response?.data?.data) {
      return response.data.data
    }
    return []
  }

  async function getAudit(id: string) {
    const response = await API.get(`${apiSlug}/${id}`)
    if (response?.data?.data) {
      const result = await afterLoad(response.data.data)
      return result
    }
    return response
  }

  async function preSaveEdit(payload) {
    delete payload.checks
    const audit = {
      responsibleId: payload.responsibleId || undefined,
      name: payload.name || undefined,
      tags: payload.tagObjects ? tagsStore.prepareTags(payload.tagObjects) : [],
      groupId: payload.groupId || undefined,
      auditDate: payload.auditDate || undefined,
      periodEndDate: payload.periodEndDate || payload.periodEndDate === null ? payload.periodEndDate : undefined,
      period: payload.period || undefined,
    }
    return audit
  }

  async function preSave(payload) {
    delete payload.id
    if (payload.tagObjects) {
      payload.tags = tagsStore.prepareTags(payload.tagObjects)
    }
    delete payload.tagObjects
    if (payload.checks) {
      payload.checks = await prepareChecks(payload.checks)
    }
    return payload
  }

  async function prepareChecks(payload: any, auditId?: any) {
    const newChecks = [] as any
    for (const check of payload) {
      const preparedCheck = {
        dueDate: check.dueDate || undefined,
        name: check.name || undefined,
        auditId: auditId || undefined,
        tags: check.tagObjects ? tagsStore.prepareTags(check.tagObjects) : undefined,
        auditorId: check?.auditor?.id || undefined,
        locationId: check?.location?.id || undefined,
        checklistId: check?.checklist?.id || undefined,
      }
      newChecks.push(preparedCheck)
    }
    return newChecks
  }

  async function create(payload: any) {
    payload.creatorId = authStore.data.id
    const response = await API.post(`${apiSlug}/createNested`, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSaveEdit(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    await sfAuditsStore.resetSelectedConditions()
    await sfAuditsStore.standartLoad()
    locationsStore.setIsLoaded(false)
    await getCountAudits()
  }

  async function addComment(payload: any) {
    if (payload.text) {
      payload.text = payload.text ? payload.text.trim() : ''
    }
    const response = await API.post('comments/', payload)
    return response
  }

  async function getComments(payload: any) {
    const response = await API.post('comments/fetch', {
      where: {
        auditId: payload,
      },
      orderBy: {
        createdAt: 'asc',
      },
      take: 1000,
      skip: 0,
    })
    if (response?.data?.data) {
      return response.data.data
    } else {
      return []
    }
  }

  async function removeChecksFromAudit(payload: any) {
    const ids = { ids: payload.map(item => item.id) }
    const response = await API.delete(`checks/batch`, ids)
    return response
  }

  async function addedChecksToAudit(payload: any, auditId: any) {
    const response = await API.post(`checks/batch`, { data: await prepareChecks(payload, auditId) })
    return response
  }

  const addZeroToDate = (value) => value < 10 ? '0' + value : '' + value

  const getAuditObjects = (payload: any) => {
    let objectsArray = []
    payload.map((item: any) => {
      if (item?.location) {
        // @ts-ignore
        objectsArray.push(item.location.name)
      }
    })
    objectsArray = [...new Set(objectsArray)]
    return objectsArray.join(', ')
  }

  function getItemColorByStatus(status) {
    // 0 - не начат, серый
    // 1 - соответствует, зеленый
    // 2 - неприменимо, зеленый
    // 3 - не проверялось, красный
    // 4 - не соответствует, красный
    const levelNum = Number(status)
    let color = ''
    switch (levelNum) {
      case 0:
        color = '#CFD3E0'
        break
      case 1:
        color = '#53C592'
        break
      case 2:
        color = '#53C592'
        break
      case 3:
        color = '#D24B64'
        break
      case 4:
        color = '#D24B64'
        break
      default:
        color = '#CFD3E0'
    }
    return color
  }

  function getItemStatus(status) {
    let iconName = ''
    switch (Number(status)) {
      case 0:
        iconName = 'pi pi-clock'
        break
      case 1:
        iconName = 'pi pi-check-circle'
        break
      case 2:
        iconName = 'pi pi-check-circle'
        break
      case 3:
        iconName = 'pi pi-times'
        break
      case 4:
        iconName = 'pi pi-times'
        break
      default:
        iconName = 'pi pi-clock'
    }
    return {
      color: getItemColorByStatus(status),
      icon: iconName,
    }
  }

  function getStatusInfo(status) {
    let checkStatus = {}
    switch (status) {
      case 0:
        checkStatus = { slug: 'pending', status: 0, color: '#CFD3E0', name: commonStore.$t.auditStatus.pending, icon: 'pi pi-clock' }
        break
      case 1:
        checkStatus = { slug: 'progress', status: 1, color: '#83DCEF', name: commonStore.$t.auditStatus.progress, icon: 'pi pi-chart-line' }
        break
      case 2:
        checkStatus = { slug: 'correct', status: 2, color: '#53C592', name: commonStore.$t.auditStatus.correct, icon: 'pi pi-check-circle' }
        break
      case 3:
        checkStatus = { slug: 'noncomformities', status: 3, color: '#D24B64', name: commonStore.$t.auditStatus.noncomformities, icon: 'pi pi-check-circle' }
        break
      default:
        checkStatus = { slug: 'pending', status: 0, color: '#CFD3E0', name: commonStore.$t.auditStatus.pending, icon: 'pi pi-clock' }
    }
    return checkStatus
  }

  const totalAudits = ref(0)
  async function getCountAudits() {
    const response = await API.post(`${apiSlug}/fetch`, { where: {}, orderBy: {}, take: 0, skip: 0 })
    totalAudits.value = response?.data?.total ? response.data.total : 0
  }

  const checks = ref([] as any)
  function addCheck(payload: any) {
    checks.value.push(payload)
  }
  function loadChecks(payload: any) {
    checks.value = [...payload]
  }
  function removeCheck(payload: any) {
    checks.value = checks.value.filter((check: any) => check?.name !== payload.name)
  }
  function clearChecks() {
    checks.value = []
  }

  return {
    data, getAll, getAudit, setData,
    isLoaded, load, setIsLoaded, afterLoad,
    isShowSidebar, setShowSidebar,
    isShowModal, setShowModal,
    isTableLoading, setTableLoading,
    auditsCalendarView, setAuditsCalendarView,
    save, create, remove, afterSuccessRequest,
    addComment, getComments,
    removeChecksFromAudit, addedChecksToAudit, prepareChecks,
    isCalendarLoading, setCalendarLoading, getAuditObjects,
    getItemColorByStatus, getItemStatus, getStatusInfo,
    totalAudits, getCountAudits, preSave,
    totalRecords, setTotalRecords, getUniqUsers,
    addZeroToDate, apiSlug,
    checks, addCheck, loadChecks, clearChecks, removeCheck,
  }
})
