import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import { createPinia } from 'pinia'
import resetStore from '@/stores/reset-store'

import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import Dialog from 'primevue/dialog'
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Tooltip from 'primevue/tooltip'
import RadioButton from 'primevue/radiobutton'
import AutoComplete from 'primevue/autocomplete'
import Card from 'primevue/card'
import TabMenu from 'primevue/tabmenu'
import Password from 'primevue/password'
import Tag from 'primevue/tag'
import Sidebar from 'primevue/sidebar'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ColorPicker from 'primevue/colorpicker'
import OverlayPanel from 'primevue/overlaypanel'
import MultiSelect from 'primevue/multiselect'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import InputMask from 'primevue/inputmask'
import MTag from './components/tag.vue'
import AddTags from './components/addTags.vue'
import InputNumber from 'primevue/inputnumber'
import Checkbox from 'primevue/checkbox'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

import '@vueform/multiselect/themes/default.css'
import './assets/scss/main.scss'
import './assets/styles/theme.css'
import './assets/styles/theme2.css'
import './assets/styles/app.css'
import './assets/styles/adaptive.css'

const pinia = createPinia()
pinia.use(resetStore)

const app = createApp(App)

app.component('Dialog', Dialog)
app.component('Sidebar', Sidebar)
app.component('Card', Card)
app.component('Button', Button)
app.component('Dropdown', Dropdown)
app.component('Calendar', Calendar)
app.component('Panel', Panel)
app.component('RadioButton', RadioButton)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('InputText', InputText)
app.component('AutoComplete', AutoComplete)
app.component('TabMenu', TabMenu)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Toast', Toast)
app.component('Password', Password)
app.component('Tag', Tag)
app.component('ColorPicker', ColorPicker)
app.component('MultiSelect', MultiSelect)
app.directive('tooltip', Tooltip)
app.component('OverlayPanel', OverlayPanel)
app.component('ConfirmDialog', ConfirmDialog)
app.component('InputMask', InputMask)
app.component('MTag', MTag)
app.component('AddTags', AddTags)
app.component('InputNumber', InputNumber)
app.component('Checkbox', Checkbox)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)

app.use(ConfirmationService)

app
  .use(router)
  .use(PrimeVue, {
    locale: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Today',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      dateFormat: 'mm/dd/yy',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      passwordPrompt: 'Enter a password',
      emptyFilterMessage: 'No results found',
      emptyMessage: 'No available options',
    },
  })
  .use(ToastService)
  .use(pinia)
  .mount('#app')

const globalProperties = app.config.globalProperties
export default globalProperties
