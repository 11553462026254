import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { usePositionsStore } from './positions'

const apiSlug = 'search'

export const useSearchStore = defineStore(apiSlug, () => {
  const positionsStore = usePositionsStore()

  const searchQuery = ref('')
  function setSearchQuery(query: '' | string) {
    searchQuery.value = query.trim()
  }

  const searchLoading = ref(true)
  function setSearchLoading(value: boolean) {
    searchLoading.value = value
  }

  async function search(query: string, limit: number) {
    setSearchLoading(true)
    const response = await API.get(`${apiSlug}?name=${query}&limit=${limit}`)
    if (response?.data) {
      const result = response.data.reduce((acc, item) => {
        if (acc[item.type]) {
          acc[item.type].push(item)
        } else {
          acc[item.type] = [item]
        }
        return acc
      }, {})
      setSearchLoading(false)
      return result
    }
    setSearchLoading(false)
    return response
  }

  async function searchElementLink(payload: any) {
    let routeName = ''
    let pathParam = ''
    switch (payload.slug) {
      case 'location':
        routeName = 'objects'
        break
      case 'checklist':
        routeName = 'checklists'
        break
      case 'audit':
        routeName = 'audit'
        break
      case 'task':
        routeName = 'tasks'
        break
      case 'group':
        routeName = 'objects&groups'
        break
      case 'category':
        routeName = 'categories'
        break
      case 'requirement':
        routeName = 'requirements'
        break
      case 'department':
        routeName = 'departments'
        break
      case 'position':
        routeName = 'positions'
        const position = await positionsStore.getById(payload.id)
        pathParam = position.departmentId
        break
      case 'tag':
        routeName = 'tags'
        break
      default:
        break
    }
    const output = {
      name: routeName,
      query: { s: payload.name },
      params: undefined as any,
    }
    if (routeName === 'positions') {
      output.params = { sId: pathParam }
    }
    return output
  }

  return {
    search, searchElementLink,
    searchQuery, setSearchQuery,
    searchLoading, setSearchLoading,
  }
})
