import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useTagsStore } from './tags'
import { useSfTasksStore } from './smartFilterTasks'
import { useCommonStore } from '@/stores/common'
import { fixTimeZoneFormat } from '@/common/utils'

const apiSlug = 'tasks'

export const useTasksStore = defineStore(apiSlug, () => {
  const tagsStore = useTagsStore()
  const sfTasksStore = useSfTasksStore()
  const commonStore = useCommonStore()

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) { isLoaded.value = value }

  const totalRecords = ref(0)
  function setTotalRecords(count: number) {
    if (totalRecords.value === count) { return }
    totalRecords.value = count
  }

  const isShowSidebar = ref(false)
  function setShowSidebar(payload: boolean) { isShowSidebar.value = payload }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) { isShowModal.value = payload }

  const isTableLoading = ref(true)
  function setTableLoading(payload: boolean) { isTableLoading.value = payload }

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
    setTableLoading(false)
  }

  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.post(`${apiSlug}/fetch`, sfTasksStore.fetchQuery)
    if (response?.data?.data) {
      setTotalRecords(response.data.total)
      const result: any[] = []
      for (const element of response.data.data) {
        result.push(await afterLoad(element))
      }
      setData(result)
    }
    setTableLoading(false)
    return response
  }

  async function getAll() {
    const response = await API.get(`${apiSlug}`)
    if (response?.data?.data) {
      return response.data.data
    }
    return []
  }

  async function afterLoad(obj: any) {
    if (obj.tags) {
      await tagsStore.setTagObjects(obj)
    }
    const taskStatus = getTaskStatus(obj)
    if (taskStatus) {
      obj.status = getTaskStatusInfo(taskStatus)
    }
    if (obj.dueDate) {
      obj.dueDate = fixTimeZoneFormat(obj.dueDate)
    }
    return obj
  }

  async function preSave(payload) {
    delete payload.id
    if (payload.tagObjects) {
      payload.tags = tagsStore.prepareTags(payload.tagObjects)
    }
    delete payload.tagObjects
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(`${apiSlug}/createNested`, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function addComment(payload: any) {
    if (payload.text) {
      payload.text = payload.text ? payload.text.trim() : ''
    }
    const response = await API.post('comments/', payload)
    return response
  }

  async function getComments(payload: any) {
    const response = await API.post('comments/fetch', {
      where: {
        taskId: payload,
      },
      orderBy: {
        createdAt: 'asc',
      },
      take: 1000,
      skip: 0,
    })
    if (response?.data?.data) {
      return response.data.data
    } else {
      return []
    }
  }

  async function afterSuccessRequest() {
    await sfTasksStore.resetFetchQuery()
  }

  function getTaskStatusInfo(status) {
    const statusInfo = {
      done: { slug: 'done', name: commonStore.$t.taskStatuses.done, color: '#53C592', icon: 'pi pi-check-circle' },
      rejected: { slug: 'rejected', name: commonStore.$t.taskStatuses.rejected, color: '#D24B64', icon: 'pi pi-times' },
      pending: { slug: 'pending', name: commonStore.$t.taskStatuses.pending, color: '#CFD3E0', icon: 'pi pi-clock' },
      overdue: { slug: 'overdue', name: commonStore.$t.taskStatuses.overdue, color: '#83DCEF', icon: 'pi pi-clock' },
    }
    return statusInfo[status]
  }

  function getTaskStatus(task) {
    // если поле done == true, то 'done'
    // если done == false, а dueDate больше текущей даты, то 'pending'
    // если done == false, и dueDate меньше текущей даты, то 'overdue'
    // если поле rejected == true, то 'rejected'
    task.done = !!task.finishDate
    let status = ''
    if (task.rejected) {
      return status = 'rejected'
    }
    if (task.done) {
      return status = 'done'
    } else {
      const currentDate = new Date()
      if (!task.dueDate) {
        return status
      }
      status = Date.parse(`${task.dueDate.toString().split('T')[0]}T23:59:59.999Z`) > +currentDate
        ? 'pending'
        : 'overdue'
    }
    return status
  }

  return {
    data, totalRecords, getAll, setData,
    isLoaded, load, setIsLoaded,
    isShowSidebar, setShowSidebar,
    isShowModal, setShowModal,
    getTaskStatusInfo, getTaskStatus,
    isTableLoading, setTableLoading,
    save, create, remove, preSave, afterSuccessRequest,
    addComment, getComments, apiSlug, setTotalRecords,
  }
})
