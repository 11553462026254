import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useTagsStore } from './tags'
import { useRequirementsStore } from './requirements'

const apiSlug = 'checklists'

export const useChecklistsStore = defineStore(apiSlug, () => {
  const tagsStore = useTagsStore()
  const requirementsStore = useRequirementsStore()

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
    setTableLoading(false)
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data?.data) {
      const result: any[] = []
      for (const element of response.data.data) {
        result.push(await afterLoad(element))
      }
      setData(result)
    }
    return response
  }
  async function afterLoad(obj: any) {
    if (obj.tags) {
      await tagsStore.setTagObjects(obj)
    }
    if (obj.requirements.length) {
      obj.requirements = obj.requirements.map((item: any) => item.Requirement)
    }
    return obj
  }

  const isShowSidebar = ref(false)
  function setShowSidebar(payload: boolean) {
    isShowSidebar.value = payload
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  const isTableLoading = ref(true)
  function setTableLoading(payload: boolean) {
    isTableLoading.value = payload
  }

  async function preSave(payload) {
    delete payload.id
    if (payload.tagObjects) {
      payload.tags = tagsStore.prepareTags(payload.tagObjects)
    }
    payload.requirements = payload.requirements || []
    delete payload.tagObjects
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  function getPreparedData(payload: any) {
    return {
      id: payload.id || undefined,
      name: payload.name || undefined,
      categoryId: payload.categoryId || undefined,
      tags: payload.tagObjects && payload.tagObjects.length ? tagsStore.prepareTags(payload.tagObjects) : [],
      requirements: payload.requirements && payload.requirements.length
        ? payload.requirements.map((item: any) => item.id)
        : [],
    }
  }

  async function batchUpdate(items: any, payload: any[]) {
    const preparedData = { data: items.map((item: any) => getPreparedData({ ...item, ...payload })) }
    const response = await API.patch(`${apiSlug}/batch`, preparedData)
    if (response?.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function batchRemove(payload: any[]) {
    const ids = { ids: payload.map(item => item.id) }
    const response = await API.delete(`${apiSlug}/batch`, ids)
    if (response?.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    requirementsStore.setIsLoaded(false)
    await load()
  }

  async function getItem(payload: any) {
    const response = await API.get(`items/${payload}`)
    if (response.status === 200 && response.data.data) {
      return response.data.data
    }
    return response
  }

  async function addComment(payload: any) {
    if (payload.text) {
      payload.text = payload.text ? payload.text.trim() : ''
    }
    const response = await API.post('comments/', payload)
    return response
  }

  async function getComments(payload: any) {
    const response = await API.post('comments/fetch', {
      where: {
        itemId: payload,
      },
      orderBy: {
        createdAt: 'asc',
      },
      take: 1000,
      skip: 0,
    })
    if (response?.data?.data) {
      return response.data.data
    } else {
      return []
    }
  }

  async function importFile(payload: any[]) {
    const response = await API.post(`${apiSlug}/import`, payload)
    if (response?.status < 300) {
      await afterSuccessRequest()
    }
    return response
  }

  async function exportFile(id: string) {
    const response = await API.get(`${apiSlug}/export/${id}`)
    if (response?.status < 300) {
      await afterSuccessRequest()
    }
    return response
  }

  return {
    data, load, isLoaded, setIsLoaded, getItem, setData,
    isShowSidebar, setShowSidebar,
    isShowModal, setShowModal,
    isTableLoading, setTableLoading,
    save, create, remove, preSave,
    batchRemove, batchUpdate, getPreparedData,
    addComment, getComments, afterSuccessRequest,
    apiSlug, importFile, exportFile
  }
})
