import { useAuthStore } from '@/stores/auth'
import { useLogoutStore } from '@/stores/logout'
import axios, {ResponseType} from 'axios'
import config from '@/config'

class API {
  public static async http(address: string, method: any, data: object = {}, headers: object = {}) {
    async function sendQuery() {
      const url = config.server + address
      const token = localStorage.token
      const defaultheaders: any = {
        'Access-Control-Allow-Origin': '*',
        'lang': localStorage[`lang`],
      }

      if (token && token !== '') {
        defaultheaders.Authorization = `Bearer ${token}`
      }

      let type: ResponseType | undefined = address.includes('files/') || address.includes('export/') && method === 'get' ? 'blob' : undefined;

      const response = await axios(url, {
        method,
        url,
        data,
        headers: { ...defaultheaders, ...headers },
        responseType: typeof headers['Name'] !== 'undefined' ? undefined : type,
      })

      return response
    }
    try {
      const response = await sendQuery()
      return response
    } catch (error: any) {
      const authStore = useAuthStore()
      const logoutStore = useLogoutStore()
      if (error.response?.data?.message === 'Unauthorized') {
        if (address === 'auth/changePassword') {
          return
        }
        if (localStorage.refreshToken) {
          const refreshToken = await authStore.reLogin(localStorage.refreshToken)
          if (refreshToken?.token) {
            const response = await sendQuery()
            return response
          }
        } else {
          if (address === 'auth/login') {
            return error.response
          }
          await logoutStore.logout()
        }
      }
      return error.response
    }
  }

  public static async get(address: string, data: object = {}, headers: object = {}) {
    return API.http(address, 'get', data, headers)
  }

  public static async post(address: string, data: object = {}, headers: object = {}) {
    return API.http(address, 'post', data, headers)
  }

  public static async delete(address: string, data: object = {}, headers: object = {}) {
    return API.http(address, 'delete', data, headers)
  }

  public static async patch(address: string, data: object = {}, headers: object = {}) {
    return API.http(address, 'patch', data, headers)
  }
}

export default API
