import { defineStore } from 'pinia'
import { useCommonStore } from './common'
import { useAuditsStore } from './audits'
import { useAuthStore } from './auth'
import { useCategoriesStore } from './categories'
import { useChecklistsStore } from './checklists'
import { useDepartmentsStore } from './departments'
import { useGroupsStore } from './groups'
import { useLocationsStore } from './locations'
import { usePositionsStore } from './positions'
import { useSearchStore } from './search'
import { useSfAuditsStore } from './smartFilterAudits'
import { useSfTasksStore } from './smartFilterTasks'
import { useTagsStore } from './tags'
import { useTasksStore } from './tasks'
import { useUsersStore } from './users'
import { useTenantsStore } from './tenants'
import router from '@/router'
import config from '@/config'
import { useFilesStore } from './files'

export const useLogoutStore = defineStore('logout', () => {
  const authStore = useAuthStore()
  const commonStore = useCommonStore()

  const auditsStore = useAuditsStore()
  const categoriesStore = useCategoriesStore()
  const checklistsStore = useChecklistsStore()
  const departmentsStore = useDepartmentsStore()
  const groupsStore = useGroupsStore()
  const locationsStore = useLocationsStore()
  const positionsStore = usePositionsStore()
  const search = useSearchStore()
  const sfAuditsStore = useSfAuditsStore()
  const sfTasksStore = useSfTasksStore()
  const tagsStore = useTagsStore()
  const tasksStore = useTasksStore()
  const usersStore = useUsersStore()
  const tenantsStore = useTenantsStore()
  const filesStore = useFilesStore()

  async function logout() {
    // reset localStore
    const lang = localStorage.lang
    localStorage.clear()
    localStorage[`lang`] = lang
    localStorage[`version`] = config.version
    // reset auth
    authStore.$reset()
    commonStore.$reset()
    filesStore.$reset()
    filesStore.clearBlobs()
    router.push('/login')
    // reset other stores
    auditsStore.$reset()
    categoriesStore.$reset()
    checklistsStore.$reset()
    departmentsStore.$reset()
    groupsStore.$reset()
    locationsStore.$reset()
    positionsStore.$reset()
    search.$reset()
    sfAuditsStore.$reset()
    sfTasksStore.$reset()
    tagsStore.$reset()
    tasksStore.$reset()
    usersStore.$reset()
    tenantsStore.$reset()
  }

  return {
    logout,
  }
})
