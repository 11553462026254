import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useTagsStore } from './tags'
import { useAuditsStore } from './audits'
import { useTasksStore } from './tasks'
import { useUsersStore } from './users'
import { useGroupsStore } from './groups'

const apiSlug = 'locations'

export const useLocationsStore = defineStore(apiSlug, () => {
  const auditsStore = useAuditsStore()
  const tagsStore = useTagsStore()
  const tasksStore = useTasksStore()
  const usersStore = useUsersStore()
  const groupsStore = useGroupsStore()

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
    setTableLoading(false)
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data?.data) {
      const result: any[] = []
      for (const element of response.data.data) {
        result.push(await afterLoad(element))
      }
      setData(result)
    }
    return response
  }
  async function afterLoad(obj: any) {
    if (obj.tags) {
      await tagsStore.setTagObjects(obj)
    }
    return obj
  }

  const isShowSidebar = ref(false)
  function setShowSidebar(payload: boolean) {
    isShowSidebar.value = payload
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  const isTableLoading = ref(true)
  function setTableLoading(payload: boolean) {
    isTableLoading.value = payload
  }

  async function preSave(payload: any) {
    delete payload.id
    if (payload.tagObjects) {
      payload.tags = tagsStore.prepareTags(payload.tagObjects)
    }
    delete payload.tagObjects
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  function getPreparedData(payload: any) {
    return {
      id: payload.id || undefined,
      name: payload.name || undefined,
      responsibleId: payload.responsibleId || undefined,
      groupId: payload.groupId || undefined,
      tags: payload.tagObjects && payload.tagObjects.length ? tagsStore.prepareTags(payload.tagObjects) : [],
    }
  }

  async function restoreVisibility(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, { hidden: false })
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function batchUpdate(items: any, payload: any[]) {
    const preparedData = { data: items.map(item => (getPreparedData({ ...item, ...payload }))) }
    const response = await API.patch(`${apiSlug}/batch`, preparedData)
    if (response?.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function batchRemove(payload: any[]) {
    const ids = { ids: payload.map(item => item.id) }
    const response = await API.delete(`${apiSlug}/batch`, ids)
    if (response?.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function importFile(payload: any[]) {
    const response = await API.post(`${apiSlug}/import`, payload)
    if (response?.status < 300) {
      await afterSuccessRequest()
    }
    return response
  }

  async function exportFile() {
    const response = await API.get(`${apiSlug}/export/`)
    if (response?.status < 300) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    auditsStore.setIsLoaded(false)
    tasksStore.setIsLoaded(false)
    usersStore.setIsLoaded(false)
    groupsStore.setIsLoaded(false)
    await load()
  }

  // TODO: import/export

  return {
    data, load, isLoaded, setIsLoaded, setData,
    isShowSidebar, setShowSidebar,
    isShowModal, setShowModal,
    isTableLoading, setTableLoading,
    save, create, remove, preSave, afterSuccessRequest,
    batchRemove, batchUpdate, getPreparedData,
    importFile, exportFile, restoreVisibility,
    apiSlug,
  }
})
