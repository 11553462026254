import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useTagsStore } from './tags'
import { useChecklistsStore } from './checklists'
import { useAuditsStore } from './audits'
import { useFilesStore } from './files'

const apiSlug = 'requirements'

export const useRequirementsStore = defineStore(apiSlug, () => {
  const tagsStore = useTagsStore()
  const checklistsStore = useChecklistsStore()
  const auditsStore = useAuditsStore()
  const filesStore = useFilesStore()

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
    setTableLoading(false)
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data?.data) {
      const result: any[] = []
      for (const element of response.data.data) {
        if (element.files.length) {
          for (const file of element.files) {
            file.url = await filesStore.getFile(file.id)
          }
        }
        result.push(await afterLoad(element))
      }
      setData(result)
    }
    return response
  }
  async function afterLoad(obj: any) {
    if (obj.tags) {
      await tagsStore.setTagObjects(obj)
    }
    return obj
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }

  const isShowSidebar = ref(false)
  function setShowSidebar(payload: boolean) {
    isShowSidebar.value = payload
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  const isTableLoading = ref(true)
  function setTableLoading(payload: boolean) {
    isTableLoading.value = payload
  }

  function reqByLevel(level) {
    const colors = {
      1: '#009600',
      2: '#2dad00',
      3: '#6ed100',
      4: '#b5f300',
      5: '#edff00',
      6: '#fff000',
      7: '#ffba00',
      8: '#ff7200',
      9: '#ff2c00',
      10: '#ff0000',
    }
    return colors[Number(level)]
  }

  async function preSave(payload) {
    delete payload.id
    if (payload.tagObjects) {
      payload.tags = tagsStore.prepareTags(payload.tagObjects)
    }
    delete payload.tagObjects
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  function getPreparedData(payload: any) {
    const filesArray = payload.files ? payload.files.map((item: any) => item.id) : undefined
    return {
      id: payload.id || undefined,
      name: payload.name || undefined,
      level: payload.level || 1,
      description: payload.description || undefined,
      tags: payload.tagObjects && payload.tagObjects.length ? tagsStore.prepareTags(payload.tagObjects) : [],
      files: filesArray,
    }
  }

  async function batchUpdate(items: any, payload: any[]) {
    const preparedData = { data: items.map((item: any) => getPreparedData({ ...item, ...payload })) }
    const response = await API.patch(`${apiSlug}/batch`, preparedData)
    if (response?.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function batchRemove(payload: any[]) {
    const ids = { ids: payload.map(item => item.id) }
    const response = await API.delete(`${apiSlug}/batch`, ids)
    if (response?.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    checklistsStore.setIsLoaded(false)
    auditsStore.setIsLoaded(false)
    await load()
  }

  // TODO: import/export

  return {
    data, load, isLoaded, setIsLoaded, setData,
    isShowSidebar, setShowSidebar,
    isShowModal, setShowModal,
    isTableLoading, setTableLoading,
    save, create, remove,
    batchRemove, batchUpdate, getPreparedData,
    reqByLevel, preSave, afterSuccessRequest,
    apiSlug,
  }
})
