import API from '@/api'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useLocationsStore } from './locations'
import { useAuditsStore } from './audits'

const apiSlug = 'groups'

export const useGroupsStore = defineStore(apiSlug, () => {
  const locationsStore = useLocationsStore()
  const auditsStore = useAuditsStore()

  const data = ref([] as any)
  function setData(payload: any) {
    data.value = payload
    if (payload.length) {
      setIsLoaded(true)
    }
  }
  async function load() {
    if (!localStorage.refreshToken) {
      return
    }
    if (isLoaded.value) {
      return
    }
    const response = await API.get(apiSlug)
    if (response?.data?.data) {
      const result: any[] = []
      for (const element of response.data.data) {
        result.push(element)
      }
      setData(result)
    }
    return response
  }

  const isLoaded = ref(false)
  function setIsLoaded(value: boolean) {
    isLoaded.value = value
  }

  const isShowModal = ref(false)
  function setShowModal(payload: boolean) {
    isShowModal.value = payload
  }

  const dataOfGroup = ref([])
  async function setDataOfGroup(payload: any) {
    dataOfGroup.value = payload
  }
  async function getById(id) {
    const response = await API.get(`${apiSlug}/${id}`)
    if (response?.data?.data) {
      await locationsStore.load()
      setDataOfGroup(locationsStore.data.filter((item: any) => item.groupId === id))
      return response.data.data
    }
    return response
  }

  async function preSave(payload) {
    delete payload.id
    return payload
  }

  async function create(payload: any) {
    const response = await API.post(apiSlug, await preSave(payload))
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function save(payload: any) {
    const response = await API.patch(`${apiSlug}/${payload.id}`, await preSave(payload))
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function remove(id) {
    const response = await API.delete(`${apiSlug}/${id}`)
    if (response.status === 200 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  async function afterSuccessRequest() {
    setIsLoaded(false)
    auditsStore.setIsLoaded(false)
    locationsStore.setIsLoaded(false)
    await load()
  }

  async function uploadImage(payload: any) {
    const response = await API.post(`${apiSlug}/upload-image`,payload, {
      'Content-Type': 'multipart/form-data'
    })
    if (response.status === 201 && response.data) {
      await afterSuccessRequest()
    }
    return response
  }

  return {
    data, load, isLoaded, setIsLoaded, setData,
    dataOfGroup, getById, preSave,
    isShowModal, setShowModal,
    save, create, remove, afterSuccessRequest,
    uploadImage,
    apiSlug,
  }
})
